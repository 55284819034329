import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Field } from 'formik';

import validate from 'components/form/validate';
import CustomInput from 'components/form/CustomInput';
import CustomSelect from 'components/form/CustomSelect';
import Select2 from 'components/form/Select2';

import UserConstants from 'constants/Users';
import citiesApi from 'api/cities';
import brandsApi from 'api/brands';

const roles = [{ value: null, title: 'Выберите роль' }].concat(UserConstants.roles);

export class OneUser extends React.Component {
  static propTypes = {};

  state = {
    cities: []
  };

  componentDidMount() {
    Promise.all([
        citiesApi.getList(),
        brandsApi.getList()
      ])
      .catch(() => [])
      .then(([cities, brands]) => this.setState(() => ({ cities, brands })));
  }

  render() {
    const { values, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>Пользователь {values.user_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field name="login" component={CustomInput} label="Логин" validate={validate} />
          <Field name="fio" component={CustomInput} label="ФИО" validate={validate} />
          <Field name="password" type="password" component={CustomInput} label="Пароль" validate={validate} />
          <Field name="re_password" type="password" component={CustomInput} label="Повтор пароля" validate={validate} />
          <Field
            name="role"
            component={CustomSelect}
            label="Роль"
            options={roles}
            validate={validate}
          />
          <Field
            name="localities"
            component={Select2}
            label="Города"
            options={this.state.cities}
            validate={validate}
            labelProp="title"
            valueProp="locality_id"
            multi
          />
          <Field
            name="brands"
            component={Select2}
            label="Бренды"
            options={this.state.brands}
            validate={validate}
            labelProp="title"
            valueProp="brand_id"
            multi
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} bsStyle="primary">Сохранить</Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

export default OneUser;
