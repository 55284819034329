import {Rest} from './api';

class PartsAPI extends Rest {
  syncJobs(){
    const url = `${this.url}/sync`
    return this.api.post(url)
      .then(({ data }) => data);
  }
}

export default new PartsAPI({url: '/parts', primaryKey: 'part_id', restore: true});
