import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { pick, pathOr, compose } from 'ramda';

import tehApi from 'api/teh';
import supervisorsApi from 'api/supervisors';
import tradesApi from 'api/trades';
import modelApi from 'api/model';

import withUser from 'components/hocs/withUser';
import withModalForm from 'components/hocs/withModalForm';
import { withInformer } from 'components/informer/Informer';
import TehView from './TehView';

export class OneTeh extends React.PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // "new" || number
    setId: PropTypes.func,
    setSaved: PropTypes.func,
    showInformer: PropTypes.func,
    user: PropTypes.object,
    path: PropTypes.string,
    requested: PropTypes.object
  };

  componentDidMount() {
    const { id, requested } = this.props;
    if (id !== 'new' && !requested.item.appl_id) {
      this.props.showInformer({ type: 'danger', text: 'Не могу найти технику' });
    }
  }

  parse(item) {
    if (!item || !item.appl_id) {
      return {};
    }

    const raw = pick(['appl_id', 'serial_num', 'status'], item);
    return Object.assign(raw, {
      locality_id: this.props.user.locality.id,
      supervisor_id: item.supervisor,
      model_id: item.model,
      comment: item.comment,
      firm: {
        title: item.addr.firm_title,
        address: item.addr.raw_address,
        trade_id: item.addr.trade
      }
    });
  }

  handleSubmit = (values, actions) => {
    console.log(values);
    const isNew = !Boolean(values.appl_id);
    const method = isNew ? tehApi.createOne : tehApi.updateOne;
    const data = {
      appl_id: values.appl_id,
      serial_num: values.serial_num,
      firm: {
        title: pathOr(undefined, ['firm', 'title'], values),
        address: pathOr(undefined, ['firm', 'address'], values),
        trade_id: pathOr(null, ['firm', 'trade_id', 'supervisor_id'], values)
      },
      supervisor_id: pathOr(undefined, ['supervisor_id', 'supervisor_id'], values),
      model_id: pathOr(undefined, ['model_id', 'model_id'], values),
      locality_id: values.locality_id,
      status: values.status,
      comment: values.comment
    };

    method.call(tehApi, data)
      .then((data) => {
        if (isNew) {
          this.props.setId(data.appl_id);
        }

        actions.setValues(this.parse(data));
        actions.setErrors({});
        actions.setSubmitting(false);
        this.props.showInformer({ type: 'success', text: 'Успешно сохранено' });
        this.props.setSaved();
      })
      .catch(({ message, errors = {} }) => {
        actions.setSubmitting(false);
        if (errors && Object.keys(errors).length) {
          this.props.showInformer({ type: 'danger', text: 'При сохранении возникли ошибки' });
          actions.setErrors(errors);
          return;
        }

        this.props.showInformer({ type: 'danger', text: message });
      });
  };

  handleHistory = () => {
    const { history, id } = this.props;
    history.push(`/teh/history/${id}`); // Warning hardcore link
  };

  render() {
    const item = this.props.requested.item;
    if (item === null) {
      return null;
    }
    const list = {
      supervisors: this.props.requested.supervisors,
      trades: this.props.requested.trades,
      models: this.props.requested.models,
      cities: this.props.user.localities.map(l => ({ title: l.title, value: l.locality_id }))
    };

    return (
      <Formik
        initialValues={this.parse(item)}
        render={(props) => <TehView {...props} lists={list} onHistoryClick={this.handleHistory} />}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default compose(
  withModalForm({
    path: '/teh/appl',
    request: async (id, { showAll }) => {
      const [item, supervisors, trades, models] = await Promise.all([
        (id === 'new' ? {} : tehApi.getOne(id, { showAll })),
        supervisorsApi.getList(),
        tradesApi.getList(),
        modelApi.getList()
      ]);

      return { item, supervisors, trades, models };
    }
  }),
  withUser,
  withInformer
)(OneTeh);
