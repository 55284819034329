import Appl from '../../../api/constants/Appl';

export default Object.assign({}, Appl, {
  i18n: {
    statuses: [
      { value: Appl.Status.NEW, title: 'Актуально' },
      { value: Appl.Status.DELETED, title: 'Удалено' }
    ]
  }
});
