import { Rest } from './api';

class Teh extends Rest {
  getBySerial(serial_num, dtQuery) {
    return this.api.get(`${this.url}/by-serial`, { params: { dtQuery, serial_num } })
      .then(({ data }) => data);
  }

  print(params = {}) {
    const query = Object.keys(params).map(key => params[key] !== null ? `${key}=${params[key]}` : false)
      .filter(Boolean)
      .join('&');
    const url = `/api${this.url}/print?${query}`; // eslint-disable-line

    window.open(this.getUserQuery(url), '_blank');
  }

  history(id) {
    return this.api.get(`${this.url}/history/${id}`)
      .then(({ data }) => data);
  }

  sync() {
    return this.api.post(`${this.url}/sync`)
      .then(({ data: { result } }) => result);
  }
}

export default new Teh({ url: '/appls', primaryKey: 'appl_id' });
