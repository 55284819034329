import React from 'react';
import { number, string, func } from 'prop-types';

const RowComponent = ({ item, onRemove }) => (
  <tr>
    <td>{item.index}</td>
    <td>{item.title}</td>
    <td>{item.count}</td>
    <td>{item.total}</td>
    <If condition={typeof onRemove === 'function'}>
      <td onClick={onRemove} style={{ cursor: 'pointer' }}>удалить</td>
    </If>
  </tr>
);
RowComponent.propTypes = {
  title: string,
  count: number,
  total: number,
  onRemove: func,
};

export default RowComponent;
