import React from 'react';
import PropTypes from 'prop-types';

export const Loader = ({ isLoading, children }) => (
  <React.Fragment>
    <If condition={isLoading}>
      Loading data...
    </If>

    <If condition={!isLoading}>
      {children}
    </If>
  </React.Fragment>
);
Loader.propTypes = { isLoading: PropTypes.bool, children: PropTypes.node };

export default Loader;
