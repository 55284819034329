import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { compose, pathOr } from 'ramda';
import { Formik, Field } from 'formik';

import validate from 'components/form/validate';
import Select2 from 'components/form/Select2';
import CustomInput from 'components/form/CustomInput';

import supervisorsApi from 'api/supervisors';
import tradesApi from 'api/trades';

import { withInformer } from 'components/informer/Informer';
import withModalForm from 'components/hocs/withModalForm';

export class TradesOne extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // "new" || number
    setId: PropTypes.func,
    setSaved: PropTypes.func,
    showInformer: PropTypes.func,
    requested: PropTypes.object
  };

  parse = (item) => {
    const supervisors = this.props.requested.supervisors;
    return Object.assign(item, {
      parent_id: supervisors.find(s => s.supervisor_id === item.parent_id)
    })
  };

  handleSubmit = (values, actions) => {
    const isNew = !Boolean(values.supervisor_id);
    const method = isNew ? tradesApi.createOne : tradesApi.updateOne;

    const saved = Object.assign({}, values, {
      parent_id: pathOr(null, ['parent_id', 'supervisor_id'], values)
    });

    method.call(tradesApi, saved)
      .then((data) => {
        if (isNew) {
          this.props.setId(data.supervisor_id);
        }

        actions.setValues(this.parse(data));
        actions.setErrors({});
        this.props.showInformer({ type: 'success', text: 'Успешно сохранено' });
        this.props.setSaved();
      })
      .catch(({ message, errors = {} }) => {
        let text = message;
        if (errors && Object.keys(errors).length) {
          text = 'При сохранении возникли ошибки';
          actions.setErrors(errors);
        }

        this.props.showInformer({ type: 'danger', text });
      });
  };

  render() {
    const { supervisors, item } = this.props.requested;
    return (
      <Formik
        initialValues={this.parse(item)}
        onSubmit={this.handleSubmit}
        render={({ values, handleSubmit }) => (
          <React.Fragment>
            <Modal.Header closeButton>
              <Modal.Title>Торговый представитель {values.supervisor_id}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Field
                name="fio"
                component={CustomInput}
                label="ФИО"
                validate={validate}
              />
              <Field name="tel1" component={CustomInput} label="Телефон" validate={validate} />
              <Field
                component={Select2}
                name="parent_id"
                label="Супервайзер"
                placeholder="Выберите супервайзера"
                options={supervisors}
                labelProp="fio"
                valueProp="supervisor_id"
              />
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={handleSubmit} bsStyle="primary">Сохранить</Button>
            </Modal.Footer>
          </React.Fragment>
        )}
      />
    );
  }
}

export default compose(
  withModalForm({
    path: '/list/trades',
    request: async (id) => {
      const [item, supervisors] = await Promise.all([
        (id === 'new' ? {} : tradesApi.getOne(id)),
        supervisorsApi.getList()
      ]);

      return { item, supervisors };
    }
  }),
  withInformer
)(TradesOne);

