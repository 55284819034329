import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';

import withUser from '../../components/hocs/withUser';
import Brands from '../../constants/Brands';
import Constants from './Constants';
import Report from './Report';
import PepsiReport from './PepsiReport';

class Reports extends React.Component {
  static propTypes = {
    user: PropTypes.Object
  };

  render() {
    const { user } = this.props;
    return (
      <Grid>
        <Row>
          <Col md={6}>
            <Report />
            <If condition={user.brand.id === Brands.pepsi}>
              <PepsiReport />
            </If>
          </Col>
          <Col md={5}>
            <Constants />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withUser(Reports);
