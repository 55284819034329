import React from 'react';
import { bool, object } from 'prop-types';
import { Panel, InputGroup, Button } from 'react-bootstrap';
import { Field } from 'formik';

import CustomInput from 'components/form/CustomInput';
import validate from 'components/form/validate';
import techApi from 'api/teh';
import ApplConstant from 'constants/Appl';

const transformDate = (db_dt) => db_dt.split('-').reverse().join('.');

export class Teh extends React.Component {
  static propTypes = {
    editable: bool,
    values: object
  };

  state = {
    fetch: false
  };

  handleFindSerial = () => {
    const { values, setValues, setFieldError } = this.props;
    const {serial_num, dt_done} = values;
    if (!serial_num) {
      return;
    }

    if (!dt_done){
      setFieldError('serial_num', 'Введите дату выполнения');
      return;
    }

    this.setState(() => ({ fetch: true }), () => {
      techApi.getBySerial(serial_num, dt_done)
        .then(({ item, passTO, error }) => {
          if (!item) {
            setFieldError('serial_num', 'Нет такой техники');
            return;
          }

          const formValues = Object.assign({}, values, {
            appl_id: item.appl_id,
            serial_num: item.serial_num,
            firm: item.addr.firm_title,
            model: item.model.title,
            address: item.addr.raw_address,
            status: item.status,
            supervisorActive: item.supervisor.active
          });

          setValues(formValues);
          const errors = [
            (passTO) ? `Техника прошла ТО: ${transformDate(passTO.dt_done)} [${passTO.order_id}]` : undefined,
            error ? error : undefined
          ].filter(Boolean);

          setFieldError('serial_num', errors.join('. '));
        })
        .then(() => this.setState(() => ({ fetch: false })))
    });
  };

  render() {
    const { values, editable } = this.props;
    const DELETED = ApplConstant.Status.DELETED;

    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Техника</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Field name="serial_num" component={CustomInput} label="Серийный номер" validate={validate} disabled={!editable}>
            <If condition={!values.order_id}>
              <InputGroup.Button>
                <Button onClick={this.handleFindSerial} disabled={this.state.fetch}>Найти</Button>
              </InputGroup.Button>
            </If>
          </Field>
          <Field readOnly name="model" component={CustomInput} label="Модель" />
          <Field name="firm" component={CustomInput} label="Клиент" validate={validate} disabled={!editable} />
          <Field name="address" component={CustomInput} label="Адрес" validate={validate} disabled={!editable} />

          <If condition={values.model && (values.status === DELETED || values.supervisorActive === false)}>
            <div>
              <a
                href={`/teh/appl/${values.appl_id}?showAll=true`}
                style={{ color: 'red', textDecoration: 'underline' }}
                target="_blank"
              >
                {values.supervisorActive === false ? 'Супервайзер удален. Кликните, чтобы изменить технику' : null}
              </a>
            </div>
          </If>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Teh;
