import { Rest } from './api';

class TradesApi extends Rest {
  getAll(params = {}) {
    return this.api.get(this.url, { params: { ...params } })
      .then(({ data }) => data);
  }
}

export default new TradesApi({ url: '/trades', primaryKey: 'supervisor_id', restore: true });

