import React from 'react';
import { object, func, shape, array } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { Field } from 'formik';
import { memoizeWith, propOr } from 'ramda';

import validate from 'components/form/validate';
import Select2 from 'components/form/Select2';
import CustomInput from 'components/form/CustomInput';
import CustomSelect from 'components/form/CustomSelect';
import ApplConstatns from 'constants/Appl';

const getTrades = memoizeWith(propOr(null, 'supervisor_id'), ({ supervisor_id = null } = {}, trades) => {
  if (supervisor_id === null) {
    return [];
  }

  return trades.filter(t => t.parent_id === supervisor_id);
});

const TehView = ({ handleSubmit, onHistoryClick, values, lists, isSubmitting }) => (
  <React.Fragment>
    <Modal.Header closeButton>
      <Modal.Title>
        Техника {values.appl_id}
        &nbsp;&nbsp;&nbsp;
        <If condition={values.appl_id}>
          <Button bsStyle="info" onClick={onHistoryClick}>История</Button>
        </If>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Field
        name="serial_num"
        component={CustomInput}
        label="Серийный номер"
        validate={validate}
        readOnly={values.appl_id}
      />
      <Field
        component={Select2}
        name="model_id"
        label="Модель"
        placeholder="Выберите модель"
        options={lists.models}
        labelProp="title"
        valueProp="model_id"
        onlySuggest
      />
      <Field
        component={CustomSelect}
        name="locality_id"
        label="Город"
        placeholder="Выберите город"
        options={lists.cities}
      />
      <Field name="firm.title" component={CustomInput} label="Клиент" validate={validate} />
      <Field name="firm.address" component={CustomInput} label="Адрес" validate={validate} />
      <Field
        component={Select2}
        name="supervisor_id"
        label="Супервайзер"
        placeholder="Выберите супервайзера"
        options={lists.supervisors}
        labelProp="fio"
        valueProp="supervisor_id"
      />
      <Field
        component={Select2}
        name="firm.trade_id"
        label="Торговый"
        placeholder="Выберите торгового"
        options={lists.trades}
        labelProp="fio"
        valueProp="supervisor_id"
      />
      <Field
        component={CustomSelect}
        name="status"
        label="Статус"
        placeholder="Выберите статус"
        options={[{
          value: ApplConstatns.Status.NEW,
          title: 'Активна'
        }, {
          value: ApplConstatns.Status.DELETED,
          title: 'Неактивна'
        }]}
      />
      <Field
        name="comment"
        component={CustomInput}
        label="Комментарий"
      />
    </Modal.Body>

    <Modal.Footer>
      <If condition={values.status !== ApplConstatns.Status.DELETED}>
        <Button onClick={handleSubmit} bsStyle="primary" disabled={isSubmitting}>Сохранить</Button>
      </If>
    </Modal.Footer>
  </React.Fragment>
);

TehView.propTypes = {
  values: object,
  handleSubmit: func,
  lists: shape({
    supervisors: array,
    models: array
  })
};
export default TehView;
