import React from 'react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { Panel, Button } from 'react-bootstrap';

import api from 'api/reports';
import CustomInput from 'components/form/CustomInput';
import { DATE_DB } from 'constants/Common';

class Report extends React.Component {
  static propTypes = {};

  handleSubmit = (values) => {
    api.createReport(values);
  };

  render() {
    const dt_to = moment({ date: 25 });
    const dt_from = dt_to.clone().subtract(1, 'month').add(1, 'day');
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Отчет по ТО</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Formik
            initialValues={{
              dt_to: dt_to.format(DATE_DB),
              dt_from: dt_from.format(DATE_DB)
            }}
            render={({ handleSubmit }) => (
              <React.Fragment>
                <Field name="dt_from" type="date" component={CustomInput} label="Дата с" />
                <Field name="dt_to" type="date" component={CustomInput} label="по" />
                <Button onClick={handleSubmit} bsStyle="primary">Создать отчет</Button>
              </React.Fragment>
            )}
            onSubmit={this.handleSubmit}
          >
          </Formik>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Report;
