import React from 'react';
import Loader from 'components/loader/Loader';
import { Route, withRouter } from 'react-router-dom';
import { withInformer } from '../informer/Informer';

const withRequest = ({ api, path, primaryKey, NewOne, perPage }, WrappedComponent) => {
  class RequestHoc extends React.Component {

    state = {
      fetch: true,
      showAll: false,
      items: [],
      count: 0,
      page: 0,
      perPage: perPage || 25,
      filter: { param: '', value: '' }
    };

    componentDidMount() {
      this.requestItems();
    }

    shouldComponentUpdate(nextProps, nextState) {
      return (this.state !== nextState) || nextProps.history.action !== 'REPLACE';
    }

    requestItems = () => {
      this.setState(() => ({ fetch: true }), () => {
        const { page, filter, perPage, showAll } = this.state;

        api.getAll({
            page,
            perPage,
            showAll,
            [filter.param]: filter.value,
          })
          .then(({ items, count, ...extra }) => {
            this.setState(() => ({ items, count, extra, fetch: false }));
          });
      });
    };

    updateItem = (item) => {
      const pk = item[primaryKey];
      const currentItem = this.state.items.find((i) => i[primaryKey] === pk);

      Object.assign(currentItem, item);
      this.setState({ items: [].concat(this.state.items) });
    };

    handleRemove = (e) => {
      e.stopPropagation(); // prevent row

      const index = e.currentTarget.closest('tr').rowIndex - 1;
      const items = this.state.items;
      const item = items[index];

      if (!item) {
        return;
      }

      const removeItem = typeof item.active === 'undefined' || item.active;

      if (removeItem && !confirm('Действительно удалить запись?')) { // eslint-disable-line
        return;
      }

      if (removeItem) {
        api.remove(item)
          .then(() => this.requestItems())
          .catch(() => this.props.showInformer({ type: 'danger', text: ' Произошла ошибка при удалении' }));
      } else {
        api.restore(item)
          .then(() => this.requestItems())
          .catch(() => this.props.showInformer({ type: 'danger', text: ' Произошла ошибка при восстановлении' }));
      }
    };

    handleFilter = ({ param, value }) => {
      this.setState(() => ({ filter: { param, value }, page: 0 }), this.requestItems);
    };

    handleChangeShowAll = (showAll) => {
      this.setState(() => ({ showAll }));
    };

    handleChangePage = (page) => {
      this.setState(() => ({ page }), this.requestItems);
    };

    handleRowClick = (item) => {
      this.props.history.push(`${path}/${item[primaryKey]}`);
    };

    handleCreate = () => {
      this.props.history.push(`${path}/new`);
    };

    renderRoute() {
      return (
        <Route
          path={`${path}/:id`}
          render={() => (
            <NewOne
              primaryKey={primaryKey}
              api={api}
              path={path}
              updateList={this.requestItems}
            />
          )}
        />
      );
    }

    render() {
      return (
        <Loader isLoading={this.state.fetch}>
          <WrappedComponent
            {...this.props}
            requestItems={this.requestItems}
            updateItem={this.updateItem}
            handleCreate={this.handleCreate}
            handleRowClick={this.handleRowClick}
            handleRemove={this.handleRemove}
            handleFilter={this.handleFilter}
            handleChangePage={this.handleChangePage}
            handleChangeShowAll={this.handleChangeShowAll}
            requestState={this.state}
          />

          {NewOne && this.renderRoute()}
        </Loader>
      );
    }
  }

  return withInformer(withRouter(RequestHoc));
};

export default withRequest;
