import React from 'react';
import { Alert } from 'react-bootstrap';

export const InformerContext = React.createContext();

const HIDE_TIMEOUT = 3 * 1000;

class Informer extends React.Component {

  state = {
    type: "",
    text: "",
    visible: false
  };

  handleShow = ({ type, text, autoHide = true }) => {
    this.setState(() => ({ type, text, visible: true }));
    if (autoHide) {
      setTimeout(this.handleHide, HIDE_TIMEOUT)
    }
  };

  handleHide = () => {
    this.setState(() => ({ visible: false }));
  };

  render() {
    return (
      <InformerContext.Provider value={{ showInformer: this.handleShow, hideInformer: this.handleHide }}>
        <If condition={this.state.visible}>
          <Alert bsStyle={this.state.type} className="center absolute wide top super-z">
            {this.state.text}
          </Alert>
        </If>
        {this.props.children}
      </InformerContext.Provider>
    );
  }
}

export const withInformer = (WrappedComponent) => (passedProps) => (
  <InformerContext.Consumer>
    {(props) => (<WrappedComponent {...props} {...passedProps} />)}
  </InformerContext.Consumer>
);

export default Informer;
