import React from 'react';
import { func } from 'prop-types';
import { Redirect } from 'react-router-dom';

import User from 'api/user';
import withUser from 'components/hocs/withUser';

class Logout extends React.Component {
  static propTypes = {
    setUser: func
  };

  constructor(props) {
    super(props);

    User.logout()
      .catch(() => null)
      .then(() => this.props.setUser(null));
  };

  render() {
    return (<Redirect to="/" />);
  }
}

export default withUser(Logout);
