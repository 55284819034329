import React from 'react';
import { shape, string, func, array } from 'prop-types';
import { FormControl, Button, FormGroup, Row, Col } from 'react-bootstrap';

import { Select2 } from 'components/form/Select2';

export class CustomAdder extends React.Component {
  static propTypes = {
    field: shape({
      name: string,
      value: array,
      onChange: func
    }).isRequired,
    valueProp: string,
    options: array.isRequired
  };

  state = {
    selected: null,
    count: 1
  };

  handleClick = () => {
    const { field: { name, value = [] }, form: { setFieldValue } } = this.props;
    const { selected, count } = this.state;
    const numberCount = parseFloat(count);

    if (selected === null || isNaN(numberCount)) {
      return;
    }

    const pastIndex = value.findIndex(v => (v.title === selected.title));
    const newValue = value.slice();

    if (pastIndex > -1) {
      newValue[pastIndex].count += numberCount;
    } else {
      newValue.push(Object.assign({}, selected, { count: numberCount, total: numberCount * selected.price }));
    }
    this.setState(() => ({ count: 1, selected: null }), () => setFieldValue(name, newValue));
  };

  handleChange = ({ currentTarget: { name, value } }) => {
    this.setState(() => ({ [name]: value, error: null }));
  };
  getOptionLabel = item => item.title;

  render() {
    return (
      <Row>
        <Col md={7}>
          <Select2
            name="selected"
            options={this.props.options}
            value={this.state.selected}
            onChange={this.handleChange}
            formatOptionLabel={this.getOptionLabel}
            valueProp={this.props.valueProp}
            label={null}
          />
        </Col>
        <Col md={2}>
          <FormGroup>
            <FormControl name="count" bsSize="sm" value={this.state.count} onChange={this.handleChange} />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Button bsStyle="primary" onClick={this.handleClick}>Добавить</Button>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default CustomAdder;
