import React from 'react';
import { shape, object, string, func, any } from 'prop-types';
import { FormGroup, InputGroup, FormControl, HelpBlock } from 'react-bootstrap';

export const CustomInput = ({ name, value, error = null, children, component, ...props }) => {
  const Component = component || FormControl;
  return (
    <FormGroup controlId={name} className="input-group-w100" validationState={error ? 'error' : null}>
      <InputGroup>
        <InputGroup.Addon className="form-label">{props.label}</InputGroup.Addon>
        <Component {...props} name={name} value={value} />
        {children}
      </InputGroup>
      {error ? <HelpBlock>{error}</HelpBlock> : null}
    </FormGroup>
  )
};
CustomInput.propTypes = {
  name: string.isRequired,
  value: any,
  label: string,
  component: func
};

const FormInput = ({ field, form: { errors }, ...props }) => (
  <CustomInput
    {...props}
    name={field.name}
    value={field.value}
    onChange={field.onChange}
    onBlur={field.onBlur}
    error={errors[field.name] ? errors[field.name] : null}
  />
);
FormInput.propTypes = {
  field: shape({
    name: string.isRequired,
    label: string
  }).isRequired,
  form: object,
  component: func
};

export default FormInput;
