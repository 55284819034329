import React from 'react';
import { arrayOf, func } from 'prop-types';
import { Form, Button } from 'react-bootstrap';

import ordersApi from 'api/orders';
import { Select2 } from 'components/form/Select2';
import { withInformer } from 'components/informer/Informer';

class MechComponent extends React.PureComponent {

  static propTypes = {
    onUpdateItems: func,
    mechanics: arrayOf(Object)
  };

  state = {
    fetch: false,
    selected: [],
  };

  handleSelectMechanic = ({ currentTarget: { value } }) => {
    this.setState({ selected: value });
  };

  handleSetMechanic = () => {
    const appls = this.props.items.reduce((acc, { serial_num, checked }) => (
      checked ? acc.concat(serial_num) : acc
    ), []);
    const mechanics = this.state.selected;

    this.setState({ fetch: true });
    ordersApi.bulkAssign({ mechanics, appls })
      .then(({ orders, mechanics }) => {
        const { items } = this.props;

        orders.forEach((order) => {
          const item = items.find(({ serial_num }) => serial_num === order.serial_num);
          if (!item) {
            throw new Error('Cannot find appl');
          }

          item.checked = false;
          item.orders = [{ order_id: order.order_id, mechanics }];
        });

        this.props.showInformer({ type: 'success', text: 'Успешно сохранено' });
        this.setState({ fetch: false }, () => this.props.onUpdateItems(items));
      })
      .catch((e) => {
        this.props.showInformer({ type: 'danger', text: 'Произошла ошибка при назначении' });
        this.setState({ fetch: false });
      });
  };

  handleReset = () => {
    const { items } = this.props;
    items.forEach((item) => item.checked = false);

    this.props.onUpdateItems(items);
  };


  handleSelectAll = () => {
    const { items } = this.props;
    const selectedAll = isAllItemsChecked(items);
    items.forEach((item) => {
      if (item.couldChecked) {
        item.checked = !selectedAll
      }
    });

    this.props.onUpdateItems(items);
  };

  renderSelected() {
    const { columns, mechanics } = this.props;
    return (
      <React.Fragment>
        <th>
          <SelectAll items={this.props.items} onClick={this.handleSelectAll} />
        </th>
        <th colSpan={columns.length - 1}>
          <Form onSubmit={this.handleFilter} inline>

            {' '}
            <div style={{ width: 450, display: 'inline-block' }}>
              <Select2
                name="mechanics"
                placeholder="Выберите механика"
                options={mechanics}
                onChange={this.handleSelectMechanic}
                value={this.state.selected}
                labelProp="fio"
                valueProp="user_id"
                maxMulti={2}
                multi
              />
            </div>
            {' '}
            <Button bsStyle="primary" onClick={this.handleSetMechanic}>Назначить</Button>
            {' '}
            <Button onClick={this.handleReset}>Сбросить</Button>
          </Form>
        </th>
      </React.Fragment>
    );
  }

  renderDefault() {
    const { columns } = this.props;
    return (
      columns.map((c, idx) => (
        idx === 0 ? this.renderID(c) : <th key={c.field}>{c.title}</th>
      ))
    );
  }

  renderID(column) {
    return (
      <th key={column.field}>
        <SelectAll items={this.props.items} onClick={this.handleSelectAll} />
        {' '}
        {column.title}
      </th>
    );
  }

  render() {
    const { columns, items } = this.props;
    const mechBarVisible = items.some(({ checked }) => checked);

    if (!mechBarVisible) {
      return this.renderDefault();
    }

    if (this.state.fetch) {
      return (
        <th colSpan={columns.length}>
          Сохранение...
        </th>
      );
    }

    return this.renderSelected();
  }
}

const SelectAll = ({ items, onClick }) => {
  const selectedAll = isAllItemsChecked(items);

  return (
    <input type="checkbox" name="selectAll" checked={selectedAll} onClick={onClick} />
  );
};

function isAllItemsChecked(items) {
  return items.every(({ couldChecked, checked }) => !couldChecked || (couldChecked && checked));
}

export default withInformer(MechComponent);
