import React from 'react';
import { number, func } from 'prop-types';
import { Pagination } from 'react-bootstrap';

const ELIPS = 10;

class CustomPaginator extends React.Component {
  static propTypes = {
    page: number,
    perPage: number,
    count: number,
    onChange: func
  };

  getPageCount() {
    const { count, perPage } = this.props;
    const pageCount = Math.floor(count / perPage);
    const total = pageCount * perPage;

    return total === count ? pageCount - 1 : pageCount;
  }

  handleChangePage = (page) => {
    this.props.onChange(page);
  };

  handleAskPage = () => {
    const { page, count, perPage } = this.props;
    const pageInput = prompt('Введите номер страницы', page + 1);
    const num = parseInt(pageInput, 10) - 1;
    const pageCount = Math.floor(count / perPage);

    if (Number.isNaN(num) || num > pageCount || num < 0) {
      return;
    }
    this.handleChangePage(num);
  };

  renderSimple(pageCount) {
    return (
      <Pagination>
        {Array.from({ length: pageCount + 1 }).map((c, page) => (
          <Pagination.Item
            key={page}
            active={page === this.props.page}
            onClick={() => this.handleChangePage(page)}
          >{page + 1}</Pagination.Item>
        ))}
      </Pagination>
    );
  }

  renderComplex(page, pageCount) {
    return (
      <Pagination>
        <Pagination.First onClick={() => this.handleChangePage(0)} />
        {getPages(this.handleChangePage, this.handleAskPage, pageCount, page)}
        <Pagination.Last onClick={() => this.handleChangePage(pageCount)} />
      </Pagination>
    );
  }

  render() {
    const pageCount = this.getPageCount();
    if (pageCount === 0) {
      return null;
    }

    const page = this.props.page;

    return (
      <div className="no-print center">
        <If condition={pageCount <= ELIPS}>
          {this.renderSimple(pageCount)}
        </If>
        <If condition={pageCount > ELIPS}>
          {this.renderComplex(page, pageCount)}
        </If>
      </div>
    );
  }
}

function getPages(handleChangePage, handleAskPage, pageCount, page) {
  const elemCount = 1;
  const middleCount = 2;
  const out = {};
  let before = page - middleCount - elemCount > 0;
  let after = page + middleCount + elemCount <= pageCount;

  let protectWhile = middleCount + elemCount;
  let counter = page;
  while (counter >= 0 && protectWhile > 0) {
    out[counter] = true;
    counter = counter - 1;
    protectWhile = protectWhile - 1;
  }

  counter = page;
  protectWhile = middleCount + elemCount;
  const last = page + middleCount >= pageCount ? pageCount : page + middleCount;
  while (counter <= last && protectWhile > 0) {
    out[counter] = true;
    counter = counter + 1;
    protectWhile = protectWhile - 1;
  }

  out[0] = true;
  out[pageCount] = true;

  const result = Object.keys(out).map(Number).map((pageNumber) => (
    <Pagination.Item key={`page-${pageNumber}`} active={page === pageNumber}
                     onClick={() => handleChangePage(pageNumber)}>
      {pageNumber + 1}
    </Pagination.Item>
  ));
  if (before) result.splice(elemCount, 0, <Pagination.Ellipsis onClick={handleAskPage} key="first" />);
  if (after) result.splice((-1 * elemCount), 0, <Pagination.Ellipsis onClick={handleAskPage} key="last" />);
  return result;
}

export default CustomPaginator;
