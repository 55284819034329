import React from 'react';
import { Formik } from 'formik';
import { Panel, Button } from 'react-bootstrap';

import api from 'api/reports'

class PepsiReport extends React.Component {
  static propTypes = {};

  handleSubmit = () => {
    api.createPepsiReport();
  };

  render() {
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Отчет по Pepsi</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Formik
            render={({ handleSubmit }) => (
              <React.Fragment>
                <Button onClick={handleSubmit} bsStyle="primary">Создать отчет</Button>
              </React.Fragment>
            )}
            onSubmit={this.handleSubmit}
          >
          </Formik>
        </Panel.Body>
      </Panel>
    );
  }
}

export default PepsiReport;
