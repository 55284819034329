import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';

import { withInformer } from 'components/informer/Informer';
import Loader from 'components/loader/Loader';
import validate from 'components/form/validate';
import CustomInput from 'components/form/CustomInput';

const OneElementHoc = (params = {}) => {
  const parse = params.parse || identity;
  const beforeSubmit = params.beforeSubmit || identity;
  const fields = params.fields || [];
  const Component = params.Component || FormConstructor;

  class OneElement extends React.Component {
    static propTypes = {
      primaryKey: PropTypes.string,
      path: PropTypes.string,
      api: PropTypes.object,
      history: PropTypes.object,
      match: PropTypes.object,
      updateList: PropTypes.func,
      showInformer: PropTypes.func
    };

    state = {
      item: null,
      fetch: true
    };

    wasSaved = false;

    componentDidMount = () => {
      const { match: { params: { id } }, api, primaryKey, showInformer } = this.props;

      Promise.resolve(id === 'new' ? {} : api.getOne(id))
        .then((item) => {
          if (id !== 'new' && !item[primaryKey]) {
            showInformer({ type: 'danger', text: 'Не могу найти выбранный элемент' });
            return;
          }

          this.setState(() => ({ item: parse(item), fetch: false }));
        });
    };

    handleClose = () => {
      const { history, path, updateList } = this.props;
      history.push(path);

      if (this.wasSaved) {
        updateList();
      }
    };

    handleSubmit = (values, actions) => {
      const { api, history, path, primaryKey } = this.props;
      const isNew = !Boolean(values[primaryKey]);
      const method = isNew ? api.createOne : api.updateOne;

      method.call(api, beforeSubmit(values))
        .then((data) => {
          if (isNew) {
            history.replace(`${path}/${data[primaryKey]}`);
          }

          actions.setValues(parse(data));
          actions.setErrors({});
          this.props.showInformer({ type: 'success', text: 'Успешно сохранено' });
          this.wasSaved = true;
        })
        .catch(({ message, errors = {} }) => {
          if (errors && Object.keys(errors).length) {
            this.props.showInformer({ type: 'danger', text: 'При сохранении возникли ошибки' });
            actions.setErrors(errors);
            return;
          }

          this.props.showInformer({ type: 'danger', text: message });
        });
    };

    render() {
      if (this.state.order === null) {
        return null;
      }

      const { fetch, item } = this.state;
      const primaryKey = this.props.primaryKey;

      return (
        <Modal backdrop={true} bsSize="large" show={true} onHide={this.handleClose} animation={false}>
          <Loader isLoading={fetch}>
            <Formik
              initialValues={item}
              render={(props) => fetch === false && <Component {...props} primaryKey={primaryKey} fields={fields} />}
              onSubmit={this.handleSubmit}
            />
          </Loader>
        </Modal>
      );
    }
  }

  return withInformer(withRouter(OneElement));
};

const FormConstructor = ({ values, fields, primaryKey, handleSubmit }) => {
  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Элемент {values[primaryKey]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fields.map(f => (
          <Field key={f.name} name={f.name} component={CustomInput} label={f.label} validate={validate} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} bsStyle="primary">Сохранить</Button>
      </Modal.Footer>
    </React.Fragment>
  )
};

export default OneElementHoc;
