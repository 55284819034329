import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'media.css';
import 'select2.css';

ReactDOM.render(
  <App />
  , document.getElementById('root'));

navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(let registration of registrations) {
    registration.unregister()
  } })
