import React from 'react';
import { shape, string, oneOfType, number, arrayOf, any } from 'prop-types';
import { FormControl } from 'react-bootstrap';

import CustomInput from './CustomInput';

const CustomSelect = ({ options, ...props }) => (
  <FormControl componentClass="select" {...props}>
    {options.map(f => (
      <option key={f.value} value={f.value}>{f.title}</option>
    ))}
  </FormControl>
);
CustomSelect.propTypes = {
  options: arrayOf(shape({
    value: any,
    title: string
  }))
};

export default (props) => <CustomInput component={CustomSelect} {...props} />;
