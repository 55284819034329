import React from 'react';

import api from 'api/teh';

export class SyncLink extends React.Component {

  state = {
    fetch: null,
    error: false
  };

  handleSync = () => {
    this.setState(() => ({ fetch: true }), () => {
      api.sync()
        .then(() => this.setState(() => ({ fetch: false }), () => this.props.updateList()))
        .catch(() => this.setState(() => ({ fetch: false, error: true })));
    });
  };

  render() {
    if (this.state.fetch) {
      return <span>Синхронизация...</span>
    }
    if (this.state.error) {
      return <span>Произошла ошибка</span>
    }
    if (this.state.fetch === false) {
      return <span>Синхронизация успешна</span>
    }
    return (<a href="#" onClick={this.handleSync}>Синхронизировать</a>);
  }
}

export default SyncLink;
