import ApplLog from '../../../api/constants/ApplLog';

export default Object.assign({}, ApplLog, {
  i18n: {
    logTypes: {
      [ApplLog.LogType.changeFirm]: 'Изменение адреса',
      [ApplLog.LogType.changeStatus]: 'Изменение статуса',
      [ApplLog.LogType.changeModel]: 'Изменение модели',
      [ApplLog.LogType.order]: 'Заявка',
      [ApplLog.LogType.created]: 'Создание техники'
    }
  }
});
