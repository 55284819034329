import React from 'react';
import { shape, number, arrayOf, string, array, func, isRequired } from 'prop-types';
import { Table } from 'react-bootstrap';

const DEFAULT_TABLE_HEADER_HEIGHT = 50;

export class CustomTable extends React.Component {
  static propTypes = {
    columns: arrayOf(
      shape({
        title: string,
        field: string
      })
    ).isRequired,
    keyField: string,
    RowComponent: func,
    HeaderComponent: func,
    items: array,
    onRowClick: func
  };

  getItem(e) {
    const index = e.currentTarget.rowIndex - 1;
    const items = this.props.items;
    return items[index];
  };

  handleRowClick = (e) => {
    if (typeof this.props.onRowClick === 'function') {
      this.props.onRowClick(this.getItem(e));
    }
  };

  renderHeader() {
    const { columns, HeaderComponent } = this.props;
    if (HeaderComponent) {
      return (
        <thead>
        <tr style={{ height: DEFAULT_TABLE_HEADER_HEIGHT }}>
          <HeaderComponent {...this.props} />
        </tr>
        </thead>
      );
    }
    return (
      <thead>
      <tr style={{ height: DEFAULT_TABLE_HEADER_HEIGHT }}>
        {columns.map(c => (<th key={c.field}>{c.title}</th>))}
      </tr>
      </thead>
    );
  }

  renderRow = (item) => {
    const { columns, RowComponent } = this.props;
    const firstField = this.props.keyField || columns[0].field;
    const key = item[firstField];

    if (RowComponent) {
      return <RowComponent key={key} {...this.props} item={item} onClick={this.handleRowClick} />;
    }

    return (
      <tr key={key} onClick={this.handleRowClick}>
        {columns.map(c =>
          <td key={`${c.field}-${key}`}>
            {c.component ? c.component(item) : item[c.field]}
          </td>
        )}
      </tr>
    );
  };

  render() {
    const items = this.props.items;
    return (
      <Table striped condensed hover>
        {this.renderHeader()}
        <tbody>
        {items && items.map(this.renderRow)}
        </tbody>
      </Table>
    );
  }
}

export default CustomTable;
