import UsersConstants from '../../../api/constants/Users';

export default Object.assign(UsersConstants, {
  roles: [
    { value: UsersConstants.AccessLevel.root, title: 'Администратор' },
    { value: UsersConstants.AccessLevel.moderator, title: 'Модератор' },
    { value: UsersConstants.AccessLevel.region, title: 'Регионы' },
    { value: UsersConstants.AccessLevel.readOnly, title: 'Торговый представитель' }
  ]
});
