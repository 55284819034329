import React from 'react';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import { Panel, FormGroup, Form, FormControl, Button } from 'react-bootstrap';

export class Filter extends React.Component {
  static propTypes = {
    filter: arrayOf(
      shape({
        title: string,
        value: string
      })
    ).isRequired,
    state: shape({
      param: string,
      value: string
    }).isRequired,
    onFilter: func.isRequired,
    onPrint: func,
    onCreate: func,
  };

  state = {
    param: this.props.state.param,
    value: ''
  };

  handleChange = (event) => {
    const { target: { id, value } } = event;
    this.setState(() => ({ [id]: value }));
  };

  handleFilter = (e) => {
    e.preventDefault();
    this.props.onFilter(this.state);
  };

  render() {
    const { filter, onPrint, width } = this.props;

    return (
      <Panel className="no-print" style={{ width: width || 840 }}>
        <Panel.Body>
          <Form onSubmit={this.handleFilter} inline>
            <If condition={this.props.onCreate}>
              <Button bsStyle="primary" type="button" onClick={this.props.onCreate}>Создать</Button>
              &nbsp; || &nbsp;
            </If>

            <FormGroup controlId="param">
              <FormControl
                componentClass="select"
                placeholder="select"
                value={this.state.param}
                onChange={this.handleChange}
              >
                {filter.map(f => (
                  <option key={f.value} value={f.value}>{f.title}</option>
                ))}
              </FormControl>
            </FormGroup>
            {'  '}
            <FormGroup controlId="value">
              <FormControl type="text" style={{ width: 300 }} value={this.state.value} onChange={this.handleChange} />
            </FormGroup>
            {'  '}
            <Button bsStyle="primary" type="submit">Поиск</Button>
            {'  '}

            <If condition={onPrint}>
              <Button bsStyle="info" type="button" onClick={onPrint}>Печать</Button>
            </If>

            {this.props.children}
          </Form>

        </Panel.Body>
      </Panel>
    );
  }
}

export default Filter;
