import React from 'react';
import { array, func, number, shape } from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

import Table from 'components/table/Table';
import Paginator from 'components/paginator/Paginator';

export const UniTable = ({ requestState: { items, count, page, perPage }, columns, ...handlers }) => (
  <React.Fragment>
    <Table
      columns={columns}
      items={items}
      {...handlers}
      onRowClick={handlers.handleRowClick}
      RowComponent={UniTable.CustomRow}
    />
    <Paginator
      count={count}
      perPage={perPage}
      page={page}
      onChange={handlers.handleChangePage}
    />
  </React.Fragment>
);
UniTable.propTypes = {
  requestState: shape({
    items: array,
    count: number,
    page: number,
    perPage: number
  }),
  columns: array,
  handleRowClick: func,
  handleRemoveItem: func,
  handleChangePage: func
};

const isActive = active => (typeof active === 'undefined' || active === true);

UniTable.CustomRow = ({ item, columns, handleRemove, onClick }) => {
  const active = isActive(item.active);
  return (
    <tr onClick={active ? onClick : null} className={active ? '' : 'tr-alert'}>
      {columns.map((c) => {
        if (c.component) {
          return (
            <td key={c.field}>
              {c.component({ ...item, handleRemove, active, onClick: c.onClick, cellValue: item[c.field] })}
            </td>
          );
        }

        return (<td key={c.field}>{item[c.field]}</td>);
      })}
    </tr>
  );
};

UniTable.UniActionCell = ({ active, handleRemove }) => (
  <div>
    <Glyphicon className="cursor-pointer" glyph={active ? 'pencil' : ''} /> {' '}
    <Glyphicon
      className="cursor-pointer"
      glyph={isActive(active) ? 'remove' : 'share-alt'}
      onClick={handleRemove}
    />
  </div>
);

UniTable.UniBooleanClickableCell = ({ active, cellValue, onClick }) => {
  const value = Boolean(cellValue) ? 'Да' : 'Нет';
  if (active) {
    return (
      <a href="#change-status" onClick={onClick} style={{ borderBottom: '1px solid #337ab7' }}>
        {value}
      </a>
    );
  }

  return value;
};

export default UniTable;
