import React from 'react';
import { Button } from 'react-bootstrap';

import usersApi from 'api/users';
import withRequest from 'components/hocs/withRequest';
import UniTable from 'components/table/UniTable';
import OneElement from 'components/OneElement';
import OneUser from './OneUser';

export class Users extends React.Component {
  static propTypes = {};

  columns = [
    { field: 'user_id', title: 'ID' },
    { field: 'fio', title: 'Имя пользователя' },
    { field: 'login', title: 'Логин' },
    { field: 'localities', title: 'Города', component: ({ localities }) => localities.map(l => l.title).join(', ') },
    { field: 'brands', title: 'Бренд', component: ({ brands }) => brands.map(l => l.title).join(', ') },
    { field: 'last_act', title: 'Последний вход' },
    { field: 'active', title: 'Активный', component: ({ active }) => active ? 'Да' : 'Нет' },
    { field: 'action', title: 'Действия', component: UniTable.UniActionCell }
  ];

  render() {
    return (
      <div>
        <Button onClick={this.props.handleCreate} bsStyle="primary">Создать</Button>
        <br />
        <UniTable
          columns={this.columns}
          requestState={this.props.requestState}
          handleChangePage={this.props.handleChangePage}
          handleRowClick={this.props.handleRowClick}
          handleRemove={this.props.handleRemove}
        />
      </div>
    );
  }
}

export default withRequest({
  primaryKey: 'user_id',
  path: '/users',
  api: usersApi,
  NewOne: OneElement({
    Component: OneUser,
    beforeSubmit: (values) => Object.assign(values, {
      locality_ids: values.localities.map(l => l.locality_id),
      brand_ids: values.brands.map(l => l.brand_id),
    })
  })
}, Users);
