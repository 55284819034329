import { Rest } from './api';

class WjobsAPI extends Rest {
  syncJobs(){
    const url = `${this.url}/sync`
    return this.api.post(url)
      .then(({ data }) => data);
  }
}

export default new WjobsAPI({ url: '/wjobs', primaryKey: 'wjob_id', restore: true });
