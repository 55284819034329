import api from './api';

const url = '/user';
const UserApi = {
  login({ login, password }) {
    return api.post(`${url}/login`, { login, password })
      .then(({ data }) => data.user);
  },

  refresh() {
    return api.get(url)
      .then(({ data }) => data.user);
  },

  changeLocality(locality_id) {
    return api.post(`${url}/set-locality`, { locality_id })
      .then(({ data: { locality } }) => locality);
  },

  changeBrand(brand_id) {
    return api.post(`${url}/set-brand`, { brand_id })
      .then(({ data: { brand } }) => brand);
  },

  logout() {
    return api.post(`${url}/logout`);
  }
};
export default UserApi;
