import React from 'react';
import { number, object, shape, array, func } from 'prop-types';
import withRequest from 'components/hocs/withRequest';
import { Button, FormControl } from 'react-bootstrap';

import tradesApi from 'api/trades';
import UniTable from 'components/table/UniTable';
import TradesOne from './TradesOne';
import supervisorsApi from 'api/supervisors';

let supervisors = [];

export class Trades extends React.Component {
  static propTypes = {
    requestState: shape({
      items: array,
      count: number,
      page: number,
      perPage: number,
      filter: object
    }),
    requestItems: func,
    handleFilter: func,
    handleChangePage: func,
    handleCreate: func,
    handleRowClick: func
  };
  columns = [
    { field: 'supervisor_id', title: 'ID' },
    { field: 'fio', title: 'ФИО' },
    { field: 'tel1', title: 'Телефон' },
    { field: 'supervisor', title: 'Супервайзер', component: ({ parent }) => <div>{parent.fio}</div> },
    { field: 'action', title: 'Действия', component: UniTable.UniActionCell }
  ];
  state = {
    supervisorId: this.props.requestState.filter.value,
    done: false
  };

  componentDidMount() {
    Promise.resolve()
      .then(() => supervisors.length ? supervisors : supervisorsApi.getList())
      .then((items) => {
        supervisors = items;
        this.setState({ done: true });
      });
  }

  handleChange = ({ currentTarget: { value } }) => {
    this.setState({ supervisorId: value });
  };

  handleFio = ({ currentTarget: { value } }) => {
    this.setState({ fio: value });
  };

  handleFilter = () => {
    const filter = {};
    if (this.state.fio) {
      Object.assign(filter, {
        param: 'fio',
        value: this.state.fio
      });
    } else {
      Object.assign(filter, {
        param: 'supervisorId',
        value: this.state.supervisorId
      });
    }

    this.props.handleFilter(filter);
  };

  handleShowAll = ({ currentTarget: { checked } }) => {
    this.props.handleChangeShowAll(checked);
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-1">
            <Button onClick={this.props.handleCreate} bsStyle="primary">Создать</Button>
          </div>
          <div className="col-md-4">
            <FormControl
              componentClass="select"
              placeholder="select"
              value={this.state.supervisorId}
              onChange={this.handleChange}
            >
              <option value="null">Все</option>
              {supervisors.map(f => (
                <option key={f.supervisor_id} value={f.supervisor_id}>{f.fio}</option>
              ))}
            </FormControl>
            <input name="fio" className="form-control" onChange={this.handleFio} value={this.state.fio} />
          </div>
          <div className="col-md-4">
            <Button onClick={this.handleFilter} bsStyle="default">Отфильтровать</Button>
            &nbsp;
            &nbsp;
            <label htmlFor="showAll">
              <input
                id="showAll"
                type="checkbox"
                onClick={this.handleShowAll}
                checked={this.props.requestState.showAll}
              />
              &nbsp;
              Показать удаленные
            </label>
          </div>
        </div>
        <br />
        <UniTable
          columns={this.columns}
          requestState={this.props.requestState}
          handleChangePage={this.props.handleChangePage}
          handleRowClick={this.props.handleRowClick}
          handleRemove={this.props.handleRemove}
        />
      </div>);
  }
}

export default withRequest({
  primaryKey: 'supervisor_id',
  path: '/list/trades',
  api: tradesApi,
  NewOne: TradesOne
}, Trades);
