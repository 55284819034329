import React from 'react';
import { Grid, Row, Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { Route, Link, Switch, Redirect } from 'react-router-dom';

import Orders from 'pages/orders/Orders';
import Users from 'pages/users/Users';
import Teh from 'pages/teh/Teh';
import List from 'pages/list/List';
import Reports from 'pages/reports/Reports';
import Logout from 'pages/logout/Logout';

const renderLocalities = (onClick, localities) => {
  return localities.map(l => (
    <MenuItem key={l.locality_id} onClick={() => onClick(l.locality_id)}>
      {l.title}
    </MenuItem>
  ));
};
const renderBrands = (onClick, brands) => {
  return brands.map(l => (
    <MenuItem key={l.brand_id} onClick={() => onClick(l.brand_id)}>
      {l.title}
    </MenuItem>
  ));
};

const MainLayout = ({ user, changeLocality, changeBrand }) => (
  <React.Fragment>
    <Navbar>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/">GIS 2.0</Link>
        </Navbar.Brand>
      </Navbar.Header>
      <Nav>
        <Navbar.Text>{user.name}</Navbar.Text>
        <NavDropdown title={user.locality.title} id="basic-nav-dropdown">
          {renderLocalities(changeLocality, user.localities)}
        </NavDropdown>
        <NavDropdown title={user.brand.title} id="basic-nav-dropdown">
          {renderBrands(changeBrand, user.brands)}
        </NavDropdown>
      </Nav>
      <Nav pullRight>
        <Navbar.Text> <Link to="/"> Заявки </Link> </Navbar.Text>
        <If condition={!user.isReadOnly}>
          <Navbar.Text> <Link to="/teh"> Техника </Link> </Navbar.Text>
          <Navbar.Text> <Link to="/list"> Списки </Link> </Navbar.Text>
        </If>
        <If condition={user.isRoot || user.isModerator}>
          <Navbar.Text> <Link to="/reports"> Отчеты </Link> </Navbar.Text>
        </If>

        <If condition={user.isRoot}>
          <Navbar.Text> <Link to="/users"> Пользователи </Link> </Navbar.Text>
        </If>

        <Navbar.Text>
          <Link to="/logout">
            Выход
          </Link>
        </Navbar.Text>
      </Nav>
    </Navbar>

    <div>
      <Grid>
        <Row className="show-grid">
          <Switch>
            <Route path="/orders" component={Orders} />
            <If condition={!user.isReadOnly}>
              <Route path="/teh" component={Teh} />
              <Route path="/list" component={List} />
            </If>
            <If condition={user.isRoot || user.isModerator}>
              <Route path="/reports" component={Reports} />
            </If>
            <If condition={user.isRoot}>
              <Route path="/users" component={Users} />
            </If>
            <Route path="/logout" component={Logout} />
            <Redirect to="/orders" />
          </Switch>
        </Row>
      </Grid>
    </div>
  </React.Fragment>
);

export default MainLayout;
