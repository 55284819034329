const UsersConstants = {
  MIN_PASSWORD_LENGTH: 3,
};
UsersConstants.AccessLevel = {
  root: 'root',
  moderator: 'moder',
  region: 'region',
  simple: 'simple',
  readOnly: 'readOnly',
};
UsersConstants.DefaultPlatformLevel = [
  UsersConstants.AccessLevel.root,
  UsersConstants.AccessLevel.moderator,
  UsersConstants.AccessLevel.region,
];

module.exports = UsersConstants;
