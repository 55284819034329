import React from 'react';
import { Panel } from 'react-bootstrap';

import mediaApi from 'api/media';

import { withInformer } from 'components/informer/Informer';

class Media extends React.Component {

  handleClickMedia = (e) => {
    e.preventDefault();
    const el = e.currentTarget;
    const img = el.cloneNode();

    img.style = '';
    window.Portal.show(img);
  };

  handleRemoveMedia = async (e) => {
    // eslint-disable-next-line
    const result = confirm('Вы действительно хотите удалить фотографию?');
    if(!result) {
      return;
    }
    const order_id = e.currentTarget.getAttribute('data-order-id');
    const media_id = e.currentTarget.getAttribute('data-media-id');

    try {
      await mediaApi.remove(order_id, media_id);
      this.props.showInformer({ type: 'success', text: 'Успешно сохранено' });
      this.props.reloadOrder();
    } catch (e) {
      console.error('Cannot fetch media', e.message);
      this.props.showInformer({ type: 'danger', text: 'Не удалось удалить фотографию' });
    }
  }

  render() {
    const { deletableMedia } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Медиа</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          {this.props.mediaData.map((media) => (
            <div style={{ display: 'inline-block', marginRight: 10, cursor: 'pointer', textAlign: 'center' }}>
              <img key={media.id} src={media.media_data} style={{ width: 150 }} onClick={this.handleClickMedia} />
              <br/>
              <If condition={deletableMedia}>
                <a href="#" onClick={this.handleRemoveMedia} data-order-id={media.order_id} data-media-id={media.id}>
                  Удалить
                </a>
              </If>
            </div>
          ))}
        </Panel.Body>
      </Panel>
    );
  }
}

export default withInformer(Media);
