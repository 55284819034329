import React from 'react';
import { number, shape, array, func } from 'prop-types';
import withRequest from 'components/hocs/withRequest';
import { Button } from 'react-bootstrap';

import citiesApi from 'api/cities';
import UniTable from 'components/table/UniTable';
import OneElement from 'components/OneElement';

export class Cities extends React.Component {
  static propTypes = {
    requestState: shape({
      items: array,
      count: number,
      page: number,
      perPage: number
    }),
    requestItems: func,
    handleFilter: func,
    handleChangePage: func,
    handleCreate: func,
    handleRowClick: func,
    handleRemove: func
  };
  static columns = [
    { field: 'locality_id', title: 'ID' },
    { field: 'title', title: 'Город' },
    { field: 'action', title: 'Действия', component: UniTable.UniActionCell }
  ];

  render() {
    return (
      <div>
        <Button onClick={this.props.handleCreate} bsStyle="primary">Создать</Button>
        <br />
        <UniTable
          columns={Cities.columns}
          requestState={this.props.requestState}
          handleChangePage={this.props.handleChangePage}
          handleRowClick={this.props.handleRowClick}
          handleRemove={this.props.handleRemove}
        />
      </div>);
  }
}

export default withRequest({
  primaryKey: 'locality_id',
  path: '/list/cities',
  api: citiesApi,
  NewOne: OneElement({
    fields: [
      { name: 'title', label: 'Название' },
    ]
  })
}, Cities);
