import api, { Rest } from './api';

class MediaAPI extends Rest {

  getOrderMedia(order_id) {
    return this.api.get(`${this.url}/order/${order_id}`)
      .then(({ data }) => data);
  }

  remove(order_id, media_id) {
    this.cache.reset();
    return api.delete(`${this.url}/order/${order_id}/${media_id}`);
  }
}

export default new MediaAPI({ url: '/media', primaryKey: 'id' });

