window.Portal = window.Portal || (function () {
  const createWrapper = () => {
    const wrapper = document.createElement('div');
    const wrapperStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: 'rgba(255, 255, 255, 0.53)',
      display: 'flex',
      zIndex: 10000,
      visibility: 'hidden',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center'
    };
    wrapper.id = 'portal-wrapper';

    Object.keys(wrapperStyle).forEach(key => wrapper.style[key] = wrapperStyle[key]);

    return wrapper;
  };

  const portalManager = {
    wrapper: null,
    content: null,

    init() {
      this.show = this.show.bind(this);
      this.hide = this.hide.bind(this);

      this.wrapper = createWrapper();
      this.wrapper.onclick = this.hide;

      document.body.appendChild(this.wrapper);

      return {
        show: this.show,
        hide: this.hide,
      };
    },

    show(el) {
      if (this.content) {
        this.removeContent();
      }

      this.content = this.createContent(el);
      this.wrapper.appendChild(this.content);
      this.wrapper.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    },

    createContent(el) {
      const content = document.createElement('div');
      const contentStyle = {
        cursor: 'default',
        position: 'relative',
        background: 'white',
        padding: '35px 15px 10px',
        border: '1px solid #cfcfcf',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px 1px #cfcfcf',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 100px)',
        maxWidth: 'calc(100vw - 50px)'
      };
      content.id = 'portal-content';

      Object.keys(contentStyle).forEach(key => content.style[key] = contentStyle[key]);
      content.appendChild(this.closeButton());
      content.appendChild(el);
      return content;
    },

    closeButton() {
      const closeButton = document.createElement('div');
      const closeButtonStyle = {
        cursor: 'pointer',
        position: 'absolute',
        top: '7px',
        right: '20px',
        borderBottom: '1px solid #cfcfcf'
      };
      closeButton.id = 'portal-closeButton';
      closeButton.innerHTML = 'Закрыть';
      closeButton.onclick = this.hide;

      Object.keys(closeButtonStyle).forEach(key => closeButton.style[key] = closeButtonStyle[key]);
      return closeButton;
    },

    hide(e) {
      if (e.target === this.wrapper || e.target.id === 'portal-closeButton') {
        this.removeContent();
        this.wrapper.style.visibility = 'hidden';
        document.body.style.overflow = 'auto';
      }
    },

    removeContent() {
      if (this.content) {
        this.wrapper.removeChild(this.content);
        this.content = null;
      }
    },
  };

  return portalManager.init();
})();
