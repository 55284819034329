import React from 'react';
import moment from 'moment-mini';
import { Label, Glyphicon } from 'react-bootstrap';

import OrderConstants from 'constants/Orders';
import { DATE_FORMAT } from 'constants/Common';

const { MehStatus, Status, OrderType } = OrderConstants;
const mehStatuses = {
  [MehStatus.DONE]: 'success',
  [MehStatus.FAILCLIENT]: 'danger',
  [MehStatus.NOTDONE]: 'danger',
  [MehStatus.NOTFOUND]: 'warning',
  [MehStatus.NOTCLIENT]: 'warning',
  [MehStatus.NEW]: ''
};
const statuses = {
  [Status.NEW]: 'list-alt',
  [Status.PROGRESS]: 'time',
  [Status.WAIT_APPROVE]: 'star-empty',
  [Status.APPROVED]: 'star',
  [Status.SKIPED]: 'share-alt',
  [Status.POSTPONED]: 'ban-circle',
  [Status.STRANGE]: 'question-sign',
  [Status.ERROR]: 'warning-sign'
};

const getClassName = (item) => {
  if (item.status === Status.POSTPONED) {
    return 'info';
  }
  return mehStatuses[item.meh_status];
};

const RowComponent = ({ item, onClick, onRemove }) => {
  const order_type = item.order_type;
  const nextTO = order_type === OrderType.NEXTTO;

  return (
    <tr className={getClassName(item)} onClick={onClick}>
      <td>
        {item.order_id}<br />
        <Glyphicon glyph="remove" onClick={onRemove} />
      </td>
      <td>
        {item.addr.firm_title} <br />
        <Label bsStyle="info">{item.appl.supervisor.fio}</Label>{' '}
        <Label bsStyle={nextTO ? 'success' : 'default'}>
          {nextTO ? 'Последующее ТО' : 'ТО'}
        </Label>
      </td>
      <td>
        {item.addr.raw_address} <br />
      </td>
      <td>
        {item.appl.model.title} <br />
        <Label style={{ fontSize: `14px` }}>{item.appl.serial_num}</Label> <br />
        {item.notes ? `Примечание: ${item.notes}` : null}
      </td>
      <td><Glyphicon glyph={statuses[item.status]} /></td>
      <td><Mechanics mechanics={item.mechanics} /></td>
      <td>
        <If condition={item.meh_status !== MehStatus.NEW}>
          <div>
            <Label bsStyle={item.confirmed ? "info": "success"}>{moment(item.dt_done).format(DATE_FORMAT)}</Label>
            <br />
          </div>
        </If>
        <Label bsStyle="default">{moment(item.dt_appl).format(DATE_FORMAT)}</Label>
      </td>
    </tr>
  );
};

const Mechanics = ({ mechanics }) => (
  mechanics.reduce((acc, m) => acc.concat(<div key={m.fio}>{m.fio}</div>), [])
);

export default RowComponent;
