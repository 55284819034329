import React from 'react';
import { func, object, bool } from 'prop-types';
import { Panel } from 'react-bootstrap';
import { Field } from 'formik';

import CustomAdder from 'components/form/CustomAdder';
import Table from 'components/table/Table';
import RowTable from './RowTable';

export class Works extends React.Component {
  static propTypes = {
    setFieldValue: func,
    values: object,
    editable: bool,
  };

  primaryKey = 'part_id';

  columns = [
    { title: 'ID', field: 'id' },
    { title: 'Запчасть', field: 'item' },
    { title: 'Кол-во', field: 'count' },
    { title: 'Итого', field: 'total' },
    { title: '', field: 'act' },
  ];

  handleRemoveItem = (e) => {
    const { values: { parts }, setFieldValue } = this.props;
    const index = e.currentTarget.parentNode.rowIndex - 1;
    const values = parts.filter((item, itemIndex) => index !== itemIndex);

    setFieldValue('parts', values);
  };

  getItems(items = []) {
    return items.map((item, index) => ({
      index: index + 1,
      title: item.title,
      count: item.count,
      total: item.total,
    }));
  };

  render() {
    const values = this.props.values;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Запчасти</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <If condition={this.props.editable}>
            <Field
              name="parts"
              placeholder="Выберите запчасть"
              valueProp="part_id"
              options={this.props.list}
              component={CustomAdder}
            />
          </If>
          <Table
            keyField="index"
            columns={this.columns}
            items={this.getItems(values.parts)}
            onRemove={this.props.editable ? this.handleRemoveItem : null}
            RowComponent={RowTable}
          />
        </Panel.Body>
      </Panel>
    );
  }
}

export default Works;
