import React from 'react';
import { UserContext } from '../../App';

function withUser(WrappedComponent) {
  class UserContextHoc extends React.Component {
    render() {
      return (
        <UserContext.Consumer>
          {(props) => <WrappedComponent {...this.props} {...props} />}
        </UserContext.Consumer>);
    }
  }

  return UserContextHoc;
}

export default withUser;
