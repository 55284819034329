import React from 'react';
import { number, shape, array, func } from 'prop-types';
import withRequest from 'components/hocs/withRequest';
import { Button } from 'react-bootstrap';

import wjobsApi from 'api/wjobs';
import UniTable from 'components/table/UniTable';
import OneElement from 'components/OneElement';
import { withInformer } from 'components/informer/Informer';

export class Wjobs extends React.Component {
  static propTypes = {
    requestState: shape({
      items: array,
      count: number,
      page: number,
      perPage: number,
    }),
    requestItems: func,
    handleFilter: func,
    handleChangePage: func,
    handleCreate: func,
    handleRowClick: func,
    handleRemove: func,
  };

  columns = [
    { field: 'wjob_id', title: 'ID' },
    { field: 'title', title: 'Работа' },
    { field: 'price', title: 'Цена' },
    {
      field: 'meh_active',
      title: 'Показывать механику',
      component: UniTable.UniBooleanClickableCell,
      onClick: (e) => this.handleUpdateMehActive(e),
    },
    { field: 'action', title: 'Действия', component: UniTable.UniActionCell },
  ];

  state = { value: this.props.requestState.filter.value || '' };

  handleUpdateMehActive = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { updateItem, requestState: { items } } = this.props;
    const index = e.currentTarget.closest('tr').rowIndex - 1;
    const item = items[index];

    Object.assign(item, { meh_active: !item.meh_active });

    updateItem(item);
    wjobsApi.updateOne(item);
  };

  handleFilter = () => {
    this.props.handleFilter({ param: 'title', value: this.state.value });
  };

  handleSyncJobs = async () => {
    try {
      this.setState({ syncJobs: true });
      await wjobsApi.syncJobs();

      this.props.showInformer({ type: 'success', text: 'Успешно синхронизированно' });
      this.setState({ syncJobs: false });
    } catch (e) {
      this.props.showInformer({ type: 'danger', text: 'Ошибка' });
      this.setState({ syncJobs: false });
    }
  };

  handleInput = ({ currentTarget: { value } }) => {
    this.setState({ value });
  };

  render() {
    const { requestState: { extra } } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-1">
            <Button onClick={this.props.handleCreate} bsStyle="primary">Создать</Button>
          </div>
          <div className="col-md-4">
            <input name="value" className="form-control" onChange={this.handleInput} value={this.state.value}/>
          </div>
          <div className="col-md-5">
            <Button onClick={this.handleFilter} bsStyle="default">Отфильтровать</Button>
            &nbsp;&nbsp;
            <If condition={extra.allowSync}>
              <Button onClick={this.handleSyncJobs} bsStyle="default" disabled={this.state.syncJobs ? 'disabled' : ''}>
                Синхронизировать
              </Button>
            </If>
          </div>
        </div>
        <br/>
        <UniTable
          columns={this.columns}
          requestState={this.props.requestState}
          handleChangePage={this.props.handleChangePage}
          handleRowClick={this.props.handleRowClick}
          handleRemove={this.props.handleRemove}
        />
      </div>
    );
  }
}

export default withInformer(
  withRequest({
    primaryKey: 'wjob_id',
    path: '/list/jobs',
    api: wjobsApi,
    NewOne: OneElement({
      fields: [
        { name: 'title', label: 'Работа' },
        { name: 'price', label: 'Цена' },
      ],
    }),
  }, Wjobs),
);
