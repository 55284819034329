const Status = {
  'NEW': 0,
  'DECLINE': -1,
  'NOMAP': -2,
  'SKIPED': -3,
  'DELETED': -100
};

module.exports = {
  Status
};
