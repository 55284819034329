import React from 'react';
import { shape, object, Fstring, bool, func, string, number, array, any } from 'prop-types';
import { FormGroup, InputGroup, HelpBlock } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

export class Select2 extends React.PureComponent {

  static propTypes = {
    name: string.isRequired,
    value: any,
    label: string,
    options: array,
    onChange: func,
    multi: bool,
    onlySuggest: bool,
    labelProp: string,
    valueProp: string,
    maxMulti: number,
    error: string
  };

  handleChange = (selectedOption) => {
    if (this.props.onChange && this.isAllowAddMulti(selectedOption)) {
      this.props.onChange({ currentTarget: { name: this.props.name, value: selectedOption } });
    }
  };

  isAllowAddMulti(selectedOption) {
    const { value, multi, maxMulti } = this.props;
    if (!multi || !value || !maxMulti) {
      return true;
    }

    return selectedOption.length <= maxMulti;
  }

  filterOptions = (value) => {
    const lower = value.toLocaleLowerCase();
    const options = this.props.options.filter(o => {
      const el = this.getOptionLabel(o).toLocaleLowerCase();
      return el.startsWith(lower);
    });

    return Promise.resolve(options);
  };

  getOptionLabel = item => item[this.props.labelProp || 'title'];
  getOptionValue = item => item[this.props.valueProp || 'value'];

  renderSelect() {
    const { name, value, options, multi, onlySuggest, disabled, ...props } = this.props;
    const Component = onlySuggest ? AsyncSelect : Select;
    return (
      <Component
        {...props}
        name={name}
        className="select2"
        value={value}
        onChange={this.handleChange}
        isMulti={Boolean(multi)}
        isDisabled={disabled}
        options={onlySuggest ? [] : options}
        maxMenuHeight={150}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        formatOptionLabel={this.getOptionLabel}
        loadOptions={this.filterOptions}
        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
      />
    )
  }

  render() {
    const { name, label, error } = this.props;

    if (!label) {
      return this.renderSelect();
    }

    return (
      <FormGroup controlId={name} bsSize="large" className="input-group-w100" validationState={error ? 'error' : null}>
        <InputGroup>
          <InputGroup.Addon className="form-label">{label}</InputGroup.Addon>
          {this.renderSelect()}
        </InputGroup>
        {error ? <HelpBlock>{error}</HelpBlock> : null}
      </FormGroup>
    );
  }
}

const FormAdapter = ({ field: { name, value }, form: { setFieldValue, errors }, ...props }) => {
  const handleChange = ({ currentTarget: { name, value } }) => {
    setFieldValue(name, value);
  };

  return (
    <Select2
      {...props}
      name={name}
      value={value}
      error={errors[name] ? errors[name] : null}
      onChange={handleChange}
    />
  );
};

export default FormAdapter;
