import React from "react";
import PropTypes from "prop-types";
import { memoize, curryN } from "ramda";
import { ButtonToolbar, Button, SplitButton, MenuItem } from "react-bootstrap";

const getSupervisorStyle = memoize(curryN(3, (selected, supervisor_id, trades) => {
  if (selected.supervisor_id === null && selected.trade_id === null) {
    return "default";
  }

  if (selected.supervisor_id === supervisor_id) {
    return "primary";
  }
  const idx = trades.findIndex(t => t.supervisor_id === selected.trade_id);
  return idx === -1 ? "default" : "info";
}));

const tradeSort = (a, b) => a.fio > b.fio ? 1 : -1;

const SupervisorWithTrades = (onChange, selected, { supervisor_id, fio, trades }) => (
  <SplitButton
    key={supervisor_id}
    title={fio}
    onClick={() => onChange(false, supervisor_id)}
    bsStyle={getSupervisorStyle(selected, supervisor_id, trades)}
    style={{ minWidth: 150 }}
  >
    {trades.sort(tradeSort).map(({ supervisor_id, fio }) => (
      <MenuItem key={supervisor_id} onClick={() => onChange(true, supervisor_id)}>{fio}</MenuItem>
    ))}
  </SplitButton>
);

const SupervisorSimple = (onChange, selected, { supervisor_id, fio }) => (
  <Button
    key={supervisor_id}
    onClick={() => onChange(false, supervisor_id)}
    bsStyle={selected.supervisor_id === supervisor_id && selected.trade_id === null ? "primary" : "default"}
    style={{ minWidth: 175 }}
  >
    {fio}
  </Button>
);

export const SupervisorPanel = ({ className, supervisors, supervisor_id, trade_id, onChange }) => {
  const arr = [{ supervisor_id: null, fio: "Все" }];
  const index = supervisors.findIndex(s => s.fio === "Неизвестно");

  if (index > -1) {
    const [nz] = supervisors.splice(index, 1);
    supervisors.push({ fio: nz.fio, supervisor_id: nz.supervisor_id });
  }
  Array.prototype.push.apply(arr, supervisors);

  const buttons = arr.map((supervisor) => {
    const button = (supervisor.trades && supervisor.trades.length) ? SupervisorWithTrades : SupervisorSimple;
    return button(onChange, { supervisor_id, trade_id }, supervisor);
  });

  return (
    <ButtonToolbar className={className}>
      {buttons}
    </ButtonToolbar>
  );
};
SupervisorPanel.propTypes = {
  supervisors: PropTypes.array,
  supervisor_id: PropTypes.number,
  trade_id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default SupervisorPanel;
