import axios from 'axios';
import { pathOr } from 'ramda';
import Cacher from './Cacher';

export class ApplicationError {
  constructor(params) {
    this.name = this.constructor.name;
    this.errors = pathOr({}, ['data', 'errors'], params);
    this.message = pathOr('Error unknown', ['data', 'message'], params);
    Error.captureStackTrace(this, ApplicationError);
  }
}

export class ServerError {
  constructor(params) {
    this.name = this.constructor.name;
    this.code = pathOr(0, ['status'], params);
    this.message = pathOr('Unknown error', ['statusText'], params);
    Error.captureStackTrace(this, ServerError);
  }
}

let currentApp = null;
const getCurrentApp = () => currentApp;

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.response.use(r => r, ({ response }) => {
  if (currentApp.state.user && [401, 403].includes(response.status)) {
    window.location = '/';
    return;
  }

  if (response.status === 400) {
    throw new ApplicationError(response);
  }

  throw new ServerError(response);
});

const getUserQuery = (user, url) => {
  const userQuery = `brandId=${user.brand.id}&localityId=${user.locality.id}`;
  const prefix = url.includes('?') ? `&` : '?';
  return `${url}${prefix}${userQuery}`;
};

api.interceptors.request.use(function (config) {
  if (!currentApp || !currentApp.state.user) {
    return config;
  }

  const { user } = currentApp.state;
  return Object.assign(config, { url: getUserQuery(user, config.url) });
});


const badRestoreAPI = () => {
  throw new Error('Cannot restore with this API');
};

export class Rest {

  constructor({ primaryKey, url, restore }) {
    this.cache = new Cacher({ getCurrentApp });
    this.primaryKey = primaryKey;
    this.url = url;
    this.api = api;
    this.restore = restore === true ? this.restore : badRestoreAPI;
  }

  getUserQuery(url) {
    const currentApp = getCurrentApp();
    const { user } = currentApp.state;
    return getUserQuery(user, url);
  }

  getOne(id, params = null) {
    return api.get(`${this.url}/${id}`, { params })
      .then(({ data }) => data);
  }

  getList() {
    const list = this.cache.get('list');
    if (list) {
      return Promise.resolve(list);
    }

    return api.get(`${this.url}/list`)
      .then(({ data: { items } }) => this.cache.add('list', items));
  }

  getAll(params = {}) {
    return api.get(this.url, { params })
      .then(({ data }) => data);
  }

  createOne(model) {
    this.cache.reset();
    return api.post(this.url, model)
      .then(({ data }) => data);
  }

  updateOne(model) {
    this.cache.reset();
    return api.patch(`${this.url}/${model[this.primaryKey]}`, model)
      .then(({ data }) => data);
  }

  remove(model, reason = null) {
    this.cache.reset();
    return api.delete(`${this.url}/${model[this.primaryKey]}`, { params: { reason } });
  }

  restore(model) {
    this.cache.reset();
    return api.post(`${this.url}/${model[this.primaryKey]}/restore`)
      .then(({ data }) => data);
  }

}

export const initApi = (app) => (currentApp = app);
export default api;
