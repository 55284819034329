import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import User from './api/user';
import { initApi, ApplicationError, ServerError } from './api/api';

import Informer from 'components/informer/Informer';
import Login from 'pages/login/Login';
import MainLayout from 'layout/Main';

export const UserContext = React.createContext(null);
const LS_KEY = 'app';
const safeParse = (str, fallback = {}) => {
  if (!str) {
    return fallback;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return fallback;
  }
};

const LS = {
  save(data) {
    const prop = this.load();
    localStorage.setItem(LS_KEY, JSON.stringify({ ...prop, ...data }));
  },
  load() {
    const data = localStorage.getItem(LS_KEY);
    return safeParse(data);
  },
};

const QS = {
  parseQs(qs) {
    const allowedFields = ['locality_id', 'brand_id'];

    return qs
      .slice(1)
      .split('&')
      .reduce((acc, pair) => {
        const [key, value] = pair.split('=');
        const intValue = Number(value);
        return Number.isInteger(intValue) && allowedFields.includes(key) ? { ...acc, [key]: intValue } : acc;
      }, {});
  },
  get() {
    const qs = (window.location.search || '');
    return qs[0] === '?' ? this.parseQs(qs) : {};
  },
  set(data) {
    const prev = this.get();
    const queryString = Object.keys({ ...prev, ...data }).reduce((acc, key) => [...acc, `${key}=${data[key]}`], []);
    const path = window.location.pathname;
    const newPath = queryString.length ? `${path}?${queryString.join('&')}` : path;

    window.history.replaceState(null, null, newPath);
  },
};

const getUserLocality = (user, locality_id) => {
  const locality = user.localities.find((locality) => locality.locality_id === locality_id);
  return locality ? { id: locality_id, title: locality.title } : null;
};

const getUserBrand = (user, brand_id) => {
  const brand = user.brands.find((brand) => brand.brand_id === brand_id);
  return brand ? { id: brand_id, title: brand.title } : null;
};

const getInitSettings = (user) => {
  const query = QS.get();
  const appUser = LS.load();
  const locality = getUserLocality(user, query.locality_id || appUser.locality_id);
  const brand = getUserBrand(user, query.brand_id || appUser.brand_id);

  return { locality, brand };
};

class App extends React.Component {
  constructor(props) {
    super(props);
    initApi(this);
  }

  state = { user: undefined };

  componentDidMount() {
    User.refresh()
      .then((user) => {
        const { locality, brand } = getInitSettings(user);

        Object.assign(user,
          locality ? { locality } : {},
          brand ? { brand } : {},
        );
        this.setState(() => ({ user }));
      })
      .catch(() => {
        this.setState(() => ({ user: null }));
      });
  }

  componentDidCatch(error) {
    if (error instanceof ServerError) {
      console.log('Server error');
    }

    if (error instanceof ApplicationError) {
      console.log('Server error');
    }
  }

  handleSetUser = (user) => {
    this.setState(() => ({ user }));
  };

  handleChangeLocality = (locality_id) => {
    const { user } = this.state;
    const locality = getUserLocality(user, locality_id);
    if (!locality) {
      throw new Error('Cannot set locality');
    }

    LS.save({ locality_id });
    QS.set({ locality_id });
    this.setState((state) => {
      const user = { ...state.user, locality };
      return { user };
    });
  };

  handleChangeBrand = (brand_id) => {
    const { user } = this.state;
    const brand = getUserBrand(user, brand_id);
    if (!brand) {
      throw new Error('Cannot find brand');
    }

    LS.save({ brand_id });
    QS.set({ brand_id });
    this.setState((state) => {
      const user = { ...state.user, brand };
      return { user };
    });
  };

  render() {
    const user = this.state.user;
    return (
      <UserContext.Provider value={{ user: user, setUser: this.handleSetUser }}>
        <Informer>
          <If condition={user}>
            <Router>
              <MainLayout
                user={user}
                changeLocality={this.handleChangeLocality}
                changeBrand={this.handleChangeBrand}
                key={`${user.locality.id}${user.brand.id}`}
              />
            </Router>
          </If>

          <If condition={user === null}>
            <Login setUser={this.handleSetUser}/>
          </If>

          <If condition={user === undefined}>
            <div>Loading...</div>
          </If>
        </Informer>
      </UserContext.Provider>
    );
  }
}

export default App;
