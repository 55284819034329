import React from 'react';
import { number, shape, array, func } from 'prop-types';
import withRequest from 'components/hocs/withRequest';
import { Button, FormControl } from 'react-bootstrap';

import supervisorsApi from 'api/supervisors';
import UniTable from 'components/table/UniTable';
import OneElement from 'components/OneElement';

export class Supervisors extends React.Component {
  static propTypes = {
    requestState: shape({
      items: array,
      count: number,
      page: number,
      perPage: number
    }),
    requestItems: func,
    handleFilter: func,
    handleChangePage: func,
    handleCreate: func,
    handleRowClick: func
  };
  columns = [
    { field: 'supervisor_id', title: 'ID' },
    { field: 'fio', title: 'ФИО' },
    { field: 'tel1', title: 'Телефон' },
    { field: 'action', title: 'Действия', component: UniTable.UniActionCell }
  ];

  state = { fio: this.props.requestState.filter.value || '' };

  handleFilter = () => {
    this.props.handleFilter({ param: 'fio', value: this.state.fio });
  };

  handleFio = ({ currentTarget: { value } }) => {
    this.setState({ fio: value });
  };

  handleShowAll = ({ currentTarget: { checked } }) => {
    this.props.handleChangeShowAll(checked);
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-1">
            <Button onClick={this.props.handleCreate} bsStyle="primary">Создать</Button>
          </div>
          <div className="col-md-4">
            <input name="fio" className="form-control" onChange={this.handleFio} value={this.state.fio} />
          </div>
          <div className="col-md-5">
            <Button onClick={this.handleFilter} bsStyle="default">Отфильтровать</Button>
            &nbsp;
            &nbsp;
            <label htmlFor="showAll">
              <input
                id="showAll"
                type="checkbox"
                onClick={this.handleShowAll}
                checked={this.props.requestState.showAll}
              />
              &nbsp;
              Показать удаленные
            </label>
          </div>
        </div>
        <br />
        <UniTable
          columns={this.columns}
          requestState={this.props.requestState}
          handleChangePage={this.props.handleChangePage}
          handleRowClick={this.props.handleRowClick}
          handleRemove={this.props.handleRemove}
        />
      </div>);
  }
}

export default withRequest({
  primaryKey: 'supervisor_id',
  path: '/list/supervisors',
  api: supervisorsApi,
  NewOne: OneElement({
    fields: [
      { name: 'fio', label: 'ФИО' },
      { name: 'tel1', label: 'Телефон' }
    ],
  })
}, Supervisors);
