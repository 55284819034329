import { Rest } from './api';

class Orders extends Rest {
  getList(params = {}) {
    return this.api.get(this.url, {
        params: Object.assign({}, params, {
          order: params.order || 'order_id',
          order_direction: params.order_direction || 'DESC',
        }),
      })
      .then(({ data }) => data);
  }

  print(params = {}) {
    const query = Object.keys(params)
      .map(key => params[key] !== null ? `${key}=${params[key]}` : false)
      .filter(Boolean)
      .join('&');

    const url = `/api${this.url}/print?${query}`; // eslint-disable-line
    window.open(this.getUserQuery(url), '_blank');
  }

  bulkAssign(body) {
    return this.api.post(`${this.url}/bulk-assign`, body)
      .then(({ data }) => data);
  }
}

export default new Orders({ url: '/orders', primaryKey: 'order_id' });

