import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Switch, Route, withRouter } from 'react-router-dom';

import withUser from 'components/hocs/withUser';
import Jobs from './Wjobs';
import Parts from './Parts';
import Mechanics from './Mechanics';
import Models from './Models';
import Supervisors from './Supervisors';
import Trades from './Trades';
import Brands from './Brands';
import Cities from './Cities';

const ButtonPath = ({ path, current, onClick, children }) => (
  <span>
    <Button onClick={() => onClick(path)} bsStyle={path === current ? 'success' : 'default'}>{children}</Button>{' '}
  </span>
);

export class List extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object
  };


  handleChangeType = (subpath) => {
    this.props.history.push(`/list/${subpath}`);
  };

  render() {
    const { user, location: { pathname } } = this.props;
    const path = pathname.split('/');
    const current = path.length === 2 ? 'jobs' : path[2];
    return (
      <div>
        <ButtonPath path="jobs" current={current} onClick={this.handleChangeType}>Работы</ButtonPath>
        <ButtonPath path='parts' current={current} onClick={this.handleChangeType}>Запчасти</ButtonPath>
        <ButtonPath path='models' current={current} onClick={this.handleChangeType}>Модели</ButtonPath>
        <ButtonPath path='mechanics' current={current} onClick={this.handleChangeType}>Механики</ButtonPath>
        <ButtonPath path='supervisors' current={current} onClick={this.handleChangeType}>Супервайзеры</ButtonPath>
        <ButtonPath path='trades' current={current} onClick={this.handleChangeType}>Торговые</ButtonPath>
        <If condition={user.isRoot}>
          <ButtonPath path='cities' current={current} onClick={this.handleChangeType}>Города</ButtonPath>
          <ButtonPath path='brands' current={current} onClick={this.handleChangeType}>Бренды</ButtonPath>
        </If>

        <br />
        <br />

        <Switch>
          <Route path="/list/parts" component={Parts} />
          <Route path="/list/models" component={Models} />
          <Route path="/list/mechanics" component={Mechanics} />
          <Route path="/list/supervisors" component={Supervisors} />
          <Route path="/list/trades" component={Trades} />
          <Route path="/list/cities" component={Cities} />
          <Route path="/list/brands" component={Brands} />
          <Route component={Jobs} />
        </Switch>
      </div>
    );
  }
}

export default withUser(withRouter(List));
