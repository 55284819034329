import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { Panel, Button } from 'react-bootstrap';

import CustomInput from 'components/form/CustomInput';
import validate from 'components/form/validate';

import reportsApi from 'api/reports';
import Loader from 'components/loader/Loader';

class Constants extends React.Component {
  static propTypes = {};

  state = {
    values: {
      price_fake: null,
      price_to: null,
      price_transport: null
    },
    fetch: true
  };

  componentDidMount() {
    const keys = Object.keys(this.state.values);
    reportsApi.getConstants(keys)
      .then((items) => this.setState(() => ({
        values: items,
        fetch: false
      })));
  }

  handleSubmit = (values) => {
    this.setState(() => ({ fetch: true }), () => {
      reportsApi.update(values)
        .then((items) => {
          this.setState(() => ({ fetch: false, values: items }));
        });
    });
  };

  render() {
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Стоимость</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Loader isLoading={this.state.fetch}>
            <Formik
              initialValues={this.state.values}
              render={({ handleSubmit }) => (
                <React.Fragment>
                  <Field name="price_to" component={CustomInput} label="Цена ТО" validate={validate} />
                  <Field name="price_fake" component={CustomInput} label="Цена ложного вызова" />
                  <Field name="price_transport" component={CustomInput} label="Цена транспорта" />
                  <Button onClick={handleSubmit} bsStyle="primary">Сохранить</Button>
                </React.Fragment>
              )}
              onSubmit={this.handleSubmit}
            >
            </Formik>
          </Loader>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Constants;
