import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import moment from 'moment-mini';

import { getIntervals, createDateContext } from '../../../../api/utils/getInterval';

import ordersApi from 'api/orders';
import supervisorApi from 'api/supervisors';
import mechanicsApi from 'api/mechanics';

import { DATE_DB } from 'constants/Common';

import { withInformer } from 'components/informer/Informer';
import Loader from 'components/loader/Loader';
import Table from 'components/table/Table';
import Paginator from 'components/paginator/Paginator';
import Filter from 'components/filter/Filter';
import SupervisorPanel from 'components/supervisorPanel/SupervisorsPanel';
import History from 'pages/history/History';

import withUser from 'components/hocs/withUser';

import 'components/portal';

import Order from './one/Order';
import RowComponent from './RowComponent';

const MECHANICS_ALL = '-1';

export class Orders extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  };

  columns = [
    { field: 'order_id', title: 'ID' },
    { field: 'client', title: 'Клиент' },
    { field: 'address', title: 'Адрес' },
    { field: 'serial_num', title: 'Техника' },
    { field: 'supervisor', title: 'Статус' },
    { field: 'master', title: 'Мастер' },
    { field: 'date', title: 'Дата' },
  ];

  orderTypes = [
    { value: 'all', title: 'Все' },
    { value: 'general', title: 'Обычные' },
    { value: 'repeat', title: 'Повторные' },
    { value: 'postponed', title: 'Отложенные' },
    { value: 'strange', title: 'Есть вопросы' },
    { value: '-', title: '---' },
    { value: 'not-approved', title: 'Незакрытые' },
    { value: 'closed-meh', title: 'Закрытые механик' },
  ];

  filter = [{ title: 'Серийный номер', value: 'serial_num' }, { title: 'ID', value: 'order_id' }];
  state = {
    items: [],
    count: 0,
    page: 0,
    perPage: 25,
    fetch: true,
    filter: { param: 'serial_num', value: '' },
    supervisor_id: null,
    trade_id: null,
    mechanic_id: null,
    from: getInterval(this.props, Date.now()).from.format(DATE_DB),
    till: getInterval(this.props, Date.now()).till.format(DATE_DB),
    supervisors: [],
    mechanics: [],
    order_type: 'all',
  };
  orderComponent = null;

  componentDidMount() {
    const promises = [supervisorApi.getList(), mechanicsApi.getList()];

    Promise.all(promises)
      .then(([supervisors, mechanicsList]) => {
        const mechanics = [{ user_id: MECHANICS_ALL, fio: 'Все' }].concat(mechanicsList);
        this.setState(() => ({ supervisors, mechanics }), this.requestItems);
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state !== nextState) || nextProps.history.action !== 'REPLACE';
  }

  requestItems = () => {
    this.setState(() => ({ fetch: true }), () => {
      const filter = this.getFilter();

      ordersApi.getAll(filter)
        .then(({ items, count }) => {
          this.setState(() => ({ items, count, fetch: false }));
        });
    });
  };

  getFilter = () => {
    const { page, filter, perPage, supervisor_id, mechanic_id, from, till, order_type } = this.state;
    return {
      page,
      perPage,
      supervisor_id,
      mechanic_id: mechanic_id === MECHANICS_ALL ? undefined : mechanic_id,
      from,
      till,
      order_type,
      dtField: 'dt_done',
      [filter.param]: filter.value,
    };
  };

  handleFilter = ({ param, value }) => {
    this.setState(() => ({ filter: { param, value }, page: 0 }), this.requestItems);
  };

  handleCustomFilter = (event) => {
    const { target: { id, value } } = event;
    this.setState(() => ({ [id]: value }));
  };

  handleChangeSupervisors = (isTrade, supervisor_id) => {
    this.setState(({ filter }) => ({
      supervisor_id: isTrade ? null : supervisor_id,
      trade_id: isTrade ? supervisor_id : null,
      page: 0,
      filter: { param: filter.param, value: '' },
    }), this.requestItems);
  };

  handleChangePage = (page) => {
    this.setState(() => ({ page }), this.requestItems);
  };

  handleRowClick = (item) => {
    this.props.history.push(`/orders/order/${item.order_id}`);
  };

  handleRemoveOrder = (e) => {
    e.stopPropagation();
    const index = e.currentTarget.closest('tr').rowIndex - 1;
    const items = this.state.items;
    const item = items[index];

    if (!item) {
      return;
    }

    if (!confirm('Действительно удалить запись?')) { // eslint-disable-line
      return;
    }

    ordersApi.remove(item)
      .then(() => this.requestItems())
      .catch(({ errors }) => this.props.showInformer({
        type: 'danger',
        text: errors.general ? errors.general : 'Произошла ошибка при удалении',
      }));
  };

  handleCreateOrder = () => {
    this.props.history.push(`/orders/order/new`);
  };

  handlePrint = () => {
    const filter = this.getFilter();
    ordersApi.print(filter);
  };

  getOrderComponent = () => {
    if (!this.orderComponent) {
      this.orderComponent = () => <Order updateList={this.requestItems}/>;
    }

    return this.orderComponent;
  };

  render() {
    const {user: {isReadOnly}}= this.props;
    return (
      <div>
        <Filter
          filter={this.filter}
          state={this.state.filter}
          onFilter={this.handleFilter}
          onCreate={isReadOnly ? null : this.handleCreateOrder}
          onPrint={isReadOnly ? null : this.handlePrint}
        >
          <div style={{ margin: '5px 0' }}>
            Период: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="date" id="from" onChange={this.handleCustomFilter} value={this.state.from}/>
            &nbsp; - &nbsp;
            <input type="date" id="till" onChange={this.handleCustomFilter} value={this.state.till}/>

            <If condition={!isReadOnly}>
              &nbsp; || &nbsp;
              <select id="order_type" onChange={this.handleCustomFilter} value={this.state.order_type}>
                {this.orderTypes.map(f => (
                  <option key={f.value} value={f.value}>{f.title}</option>
                ))}
              </select>
              &nbsp; || &nbsp;
              <select id="mechanic_id" onChange={this.handleCustomFilter} value={this.state.mechanic_id}>
                {this.state.mechanics.map(f => (
                  <option key={f.user_id} value={f.user_id}>{f.fio}</option>
                ))}
              </select>
            </If>
          </div>
          Всего записей: {this.state.count}
        </Filter>

        <If condition={!isReadOnly}>
          <SupervisorPanel
            supervisors={this.state.supervisors}
            supervisor_id={this.state.supervisor_id}
            trade_id={this.state.trade_id}
            onChange={this.handleChangeSupervisors}
          />
        </If>

        <br/>
        <Loader isLoading={this.state.fetch}>
          <Table
            columns={this.columns}
            items={this.state.items}
            onRowClick={this.handleRowClick}
            onRemove={this.handleRemoveOrder}
            RowComponent={RowComponent}
          />
          <Paginator
            count={this.state.count}
            perPage={this.state.perPage}
            page={this.state.page}
            onChange={this.handleChangePage}
          />
        </Loader>

        <Route path="/orders/order/:id" component={this.getOrderComponent()}/>
        <Route path="/orders/history/:id" component={History}/>
      </div>
    );
  }
}

function getInterval(props, dt) {
  const { user } = props;
  const context = createDateContext(user.locality.id, user.brand.id);
  const dtm = moment(dt);
  if (!dtm.isValid()) {
    throw new Error('Bad datetime in get interval');
  }

  const intervals = getIntervals(context);
  const interval = intervals.find(i => dtm.isBetween(i[0], i[1], null, '[]'));
  if (!interval) {
    throw new Error('Bad datetime in get interval between');
  }

  return { from: interval[0], till: interval[1] };
}

export function getIntervalIndex(props) {
  const { user } = props;
  const context = createDateContext(user.locality.id, user.brand.id);

  const dtm = moment();
  const intervals = getIntervals(context);
  return intervals.findIndex(i => dtm.isBetween(i[0], i[1], null, '[]'));
}

export default withUser(withInformer(withRouter(Orders)));
