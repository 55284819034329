const Status = {
  NEW: 0,//created only
  PROGRESS: 1, //assign to mechanic
  WAIT_APPROVE: 2, //mechanic set status, wait approve
  APPROVED: 3,//application approved, do nothing
  POSTPONED: 4,//order is not processed, waiting spec approval
  STRANGE: 5,//some question about order
};
const MehStatus = {
  NEW: 0,
  NOTDONE: -1,
  NOTFOUND: -2,
  NOTCLIENT: -3,
  FAILCLIENT: -4,
  FAKE: -100,
  DONE: 1,
  LINK_TO_STRANGE: -101
};

const OrderType = {
  TO: 0,
  TODAY: 1,
  NEXTTO: 2,
  SELF: -1,
  REPEAT: -2
};

module.exports = {Status, MehStatus, OrderType};
