import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';

import User from 'api/user';
import './login.css';
import { withInformer } from 'components/informer/Informer';
import { ServerError, ApplicationError } from 'api/api';

class Login extends React.Component {
  static propTypes = {
    setUser: PropTypes.func
  };

  state = {
    login: '',
    password: '',
    fetch: false
  };

  handleSubmit = (e) => {
    e.preventDefault();
    User.login(this.state)
      .then((user) => this.props.setUser(user))
      .catch((error) => {
        if (error instanceof ServerError) {
          this.props.showInformer({ type: 'danger', text: error.message });
        }

        this.setState(() => ({
          login: '',
          password: ''
        }));
      });
  };

  handleChange = (event) => {
    const { target: { id, value } } = event;
    this.setState(() => ({ [id]: value, error: null }));
  };

  validateForm = () => {
    const { login, password } = this.state;
    return login.length && password.length;
  };

  getButtonText() {
    return this.state.fetch ? 'Wait...' : 'Login';
  }

  render() {

    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="login" bsSize="large">
            <ControlLabel>Login</ControlLabel>
            <FormControl
              autoFocus
              value={this.state.login}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            bsStyle={this.state.error ? 'danger' : 'primary'}
            disabled={!this.validateForm() || this.state.fetch}
            type="submit"
          >
            {this.getButtonText()}
          </Button>
        </form>
      </div>
    );
  }
}

export default withInformer(Login);
