import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import moment from 'moment-mini';
import { compose, defaultTo, prop, propEq } from 'ramda';

import withModalForm from 'components/hocs/withModalForm';
import techApi from 'api/teh';
import Table from 'components/table/Table';
import ApplLog from 'constants/ApplLog';
import ApplConstants from 'constants/Appl';
import Order from 'constants/Orders';
import { DATE_FORMAT } from 'constants/Common';

const i18nChanges = { address: 'Адрес', location: 'Город', firm: 'Фирма', status: 'Статус' };
const getI18nStatus = compose(
  prop('title'),
  defaultTo({ title: 'Нет статуса' }),
  status => ApplConstants.i18n.statuses.find(propEq('value', status))
);

const getAction = (item, index) => {
  const i18n = ApplLog.i18n.logTypes;
  let notes = '';

  switch (item.log_type) {
    case ApplLog.LogType.order:
      const { order_id, dt_done, address, city, firm, meh_status } = item.change_log;
      const status = Order.i18n.meh_status.find(m => m.value === meh_status);
      const dt = moment(dt_done).format(DATE_FORMAT);
      notes = `#${order_id} (${status.title}) :: ${dt} - ${firm} (${city}, ${address})`;
      break;

    case ApplLog.LogType.changeStatus: {
      const { prev, current } = JSON.parse(item.change_log);
      notes = `Сменили статус ${getI18nStatus(prev)} на ${getI18nStatus(current)}`;
    }
      break;

    case ApplLog.LogType.changeModel: {
      const { prev, current } = JSON.parse(item.change_log);
      notes = `Сменили с ${prev} на ${current}`;
    }
      break;

    case ApplLog.LogType.changeFirm:
      const json = JSON.parse(item.change_log);
      notes = Object.keys(json).map((key) => {
        return `${i18nChanges[key]}:: ${json[key].prev} => ${json[key].current}`;
      }).join('; ');
      break;
  }

  return {
    index,
    action: i18n[item.log_type],
    notes,
    dt_create: moment(item.dt_create).format(DATE_FORMAT),
    user: item.user && item.user.fio ? item.user.fio : 'Система'
  };
};

export class History extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    requested: PropTypes.shape({
      log: PropTypes.array,
      serial_num: PropTypes.string
    })
  };

  columns = [
    { field: 'dt_create', title: 'Дата' },
    { field: 'action', title: 'Действие' },
    { field: 'notes', title: 'Примечание' },
    { field: 'user', title: 'Пользователь' },
    { field: 'index', title: '', component: () => '' }
  ];

  getItems() {
    const items = this.props.requested.log;
    return items.map(getAction);
  }

  render() {
    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>История изменений техники {this.props.id} ({this.props.requested.serial_num})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table keyField={'index'} columns={this.columns} items={this.getItems()} />
        </Modal.Body>
      </React.Fragment>
    )
  }
}

export default withModalForm({
  request: async (id) => await techApi.history(id),
  onClose() {
    const history = this.props.history;
    history.go(-1);
  }
})(History);
