import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { path } from 'ramda';

import Loader from 'components/loader/Loader';

const getPathId = path(['match', 'params', 'id']);

const withModalForm = options => WrappedComponent => {
  class ModalFormHoc extends React.Component {
    static propTypes = {
      updateList: PropTypes.func,
      filter: PropTypes.object // for filter (ex. teh)
    };

    state = {
      fetch: true,
      requested: null
    };
    wasSaved = false;

    componentDidMount() {
      if (!options.request) {
        this.setState(() => ({ fetch: false }));
        return;
      }
      const id = getPathId(this.props);

      options.request(id, this.props.filter)
        .then((requested) => {
          this.setState(() => ({
            fetch: false,
            requested
          }));
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
      if (this.state !== nextState) {
        return true;
      }

      const id = getPathId(this.props);
      return (getPathId(nextProps) !== id && id !== 'new');
    }

    setSaved = () => {
      this.wasSaved = true;
    };

    setId = (id) => this.props.history.push(`${options.path}/${id}`);

    handleClose = () => {
      if (options.onClose) {
        return options.onClose.call(this);
      }

      this.props.history.push(options.path);
      if (this.wasSaved && this.props.updateList) {
        this.props.updateList();
      }
    };

    render() {
      const { match: { params: { id } } } = this.props;
      const { fetch, requested } = this.state;

      return (
        <Modal backdrop={true} bsSize="large" show={true} onHide={this.handleClose} animation={false}>
          <Loader isLoading={fetch}>
            <WrappedComponent
              id={id}
              path={options.path}
              requested={requested}
              setId={this.setId}
              setSaved={this.setSaved}
              history={this.props.history}
            />
          </Loader>
        </Modal>
      )
    }
  }

  return withRouter(ModalFormHoc);
};

export default withModalForm;
