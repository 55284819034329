import { Rest } from './api';

class Reports extends Rest {

  getConstants(params) {
    return this.api.get(`${this.url}/get-constants`, { params: { params } })
      .then(({ data }) => data.items);
  }

  update(values) {
    return this.api.post(`${this.url}/update-constants`, { values })
      .then(({ data }) => data.items);
  }

  createReport({dt_from, dt_to}){
    const url = `/legacy_report/ReportTO.php?dt_from=${dt_from}&dt_to=${dt_to}`;
    window.open(this.getUserQuery(url), '__blank');
  }

  createPepsiReport(){
    const url = `/api/reports/pepsi-report`;
    window.open(this.getUserQuery(url), '__blank');
  }

}

export default new Reports({ url: '/reports', primaryKey: 'param' });
