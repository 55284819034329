import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { Field } from 'formik';

import CustomInput from 'components/form/CustomInput';
import CustomSelect from 'components/form/CustomSelect';
import validate from 'components/form/validate';

import Select2 from 'components/form/Select2';
import OrderConstants from 'constants/Orders';

class Detail extends React.Component {
  static propTypes = {
    list: PropTypes.array,
    editable: PropTypes.bool,
  };

  render() {
    const { editable } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3">Общая информация</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Field
            name="dt_done"
            type="date"
            component={CustomInput}
            label="Дата выполнения"
            validate={validate}
            disabled={!editable}
          />
          <Field
            name="meh_status"
            component={CustomSelect}
            label="Cтатус"
            options={OrderConstants.i18n.meh_status}
            validate={validate}
            disabled={!editable}
          />
          <Field
            component={Select2}
            name="mechanics"
            label="Механик"
            placeholder="Выберите механика"
            options={this.props.list}
            labelProp="fio"
            valueProp="user_id"
            maxMulti={2}
            multi
            disabled={!editable}
          />
          <Field
            name="notes"
            component={CustomInput}
            label="Примечание"
            disabled={!editable}
          />
        </Panel.Body>
      </Panel>
    );
  }
}

export default Detail;
