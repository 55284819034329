const expireTime = 5 * 60 * 1000;

const getUserBrandLocalityKey = (app) => {
  const { user } = app.state;
  return `${user.brand.id}:${user.locality.id}`;
};

class Cacher {

  cache = {};

  constructor(props = {}) {
    this.getCurrentApp = props.getCurrentApp;
    this.expireTime = expireTime;
  }

  keyWithPrefix = (key) => {
    const prefix = getUserBrandLocalityKey(this.getCurrentApp());
    return `${prefix}:${key}`;
  };

  add(key, value) {
    const fullKey = this.keyWithPrefix(key);
    this.cache[fullKey] = { value, time: +new Date() };
    return value;
  }

  get(key) {
    const fullKey = this.keyWithPrefix(key);
    if (typeof this.cache[fullKey] === 'undefined') {
      return undefined;
    }

    const { value, time } = this.cache[fullKey];
    const currTime = +new Date();
    return currTime - time > this.expireTime ? this.remove(fullKey) : value;
  }

  remove(key) {
    const fullKey = this.keyWithPrefix(key);
    this.cache[fullKey] = undefined;
  }

  reset() {
    this.cache = {};
  }

}

export default Cacher;
