import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { pick } from 'ramda';

import ordersApi from 'api/orders';
import wjobApi from 'api/wjobs';
import partsApi from 'api/parts';
import mechanicsApi from 'api/mechanics';
import mediaApi from 'api/media';

import OrdersConstants from 'constants/Orders';
import Loader from 'components/loader/Loader';
import { withInformer } from 'components/informer/Informer';
import withUser from 'components/hocs/withUser';
import OrderView from './OrderView';

class OneOrder extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    updateList: PropTypes.func,
  };

  state = {
    fetch: true,
    order: undefined,
    lists: {
      parts: [],
      wjobs: [],
      mechanics: [],
    },
  };
  wasSaved = false;
  order = false;

  componentDidMount = () => this.fetchData();

  fetchData = async () => {
    const { Status, MehStatus } = OrdersConstants;
    const { match: { params: { id } } } = this.props;

    this.setState(() => ({ lists: {}, fetch: true }));

    const [order, wjobs, parts, mechanics] = await Promise.all([
      (id === 'new' ? {} : ordersApi.getOne(id)),
      wjobApi.getList(),
      partsApi.getList(),
      mechanicsApi.getList(),
    ]);

    if (id !== 'new') {
      if (!order.order_id) {
        this.props.showInformer({ type: 'danger', text: 'Не могу найти заявку' });
        return;
      }

      if (order.status !== Status.NEW && order.meh_status !== MehStatus.NEW) {
        try {
          const media = await mediaApi.getOrderMedia(order.order_id);
          Object.assign(order, { media });
        } catch (e) {
          console.error('Cannot fetch media', e.message);
        }
      }
    }

    this.order = this.parse(order);
    this.setState(() => ({ lists: { mechanics, wjobs, parts }, fetch: false }));
  };

  parse(order) {
    if (!order || !order.order_id) {
      return {};
    }

    const raw = pick(
      ['order_id', 'dt_done', 'status', 'confirmed', 'appl_id', 'meh_status', 'media', 'mechanics', 'notes'],
      order,
    );
    return Object.assign(raw, {
      model: order.appl.model.title,
      address: order.addr.raw_address,
      serial_num: order.appl.serial_num,
      firm: order.addr.firm_title,
      media: order.media,
      jobs: order.jobs.map(j => ({
        wjob_id: j.wjob_id,
        title: j.title,
        price: j.price,
        count: j.ordersWjob.count,
        total: j.ordersWjob.total,
      })),
      parts: order.parts.map(p => ({
        part_id: p.part_id,
        title: p.title,
        price: p.price,
        count: p.orderParts.count,
        total: p.orderParts.total,
      })),
    });
  }

  handleClose = () => {
    this.props.history.push(`/orders`);
    if (this.wasSaved) {
      this.props.updateList();
    }
  };

  handleSubmit = (values, actions) => {
    const isNew = !Boolean(values.order_id);
    const method = isNew ? ordersApi.createOne : ordersApi.updateOne;

    if (isNew) {
      values.dt_appl = values.dt_done;
    }

    return method.call(ordersApi, values)
      .then((data) => {
        if (isNew) {
          this.props.history.replace(`/orders/order/${data.order_id}`);
        }

        this.order = this.parse(data);
        actions.setValues(this.order);
        actions.setErrors({});
        actions.setSubmitting(false);
        this.props.showInformer({ type: 'success', text: 'Успешно сохранено' });
        this.wasSaved = true;
        return this.fetchData();
      })
      .catch(({ message, errors = {} }) => {
        actions.setSubmitting(false);

        if (errors && Object.keys(errors).length) {
          this.props.showInformer({ type: 'danger', text: 'При сохранении возникли ошибки' });
          actions.setErrors(errors);
          return;
        }
        this.props.showInformer({ type: 'danger', text: message });
      });
  };

  handleHistory = () => {
    this.props.history.push(`/orders/history/${this.order.appl_id}`);
  };

  render() {
    const { fetch } = this.state;
    if (!this.order && !fetch) {
      return null;
    }

    return (
      <Modal backdrop={true} bsSize="large" show={true} onHide={this.handleClose} animation={false}>
        <Loader isLoading={fetch}>
          <If condition={!fetch}>
            <Formik
              initialValues={this.order}
              render={(props) => fetch === false &&
                <OrderView
                  {...props}
                  lists={this.state.lists}
                  user={this.props.user}
                  reloadOrder={this.fetchData}
                  onHistoryClick={this.handleHistory}
                />}
              onSubmit={this.handleSubmit}
            />
          </If>
        </Loader>
      </Modal>
    );
  }
}

export default withUser(withInformer(withRouter(OneOrder)));
