import React from 'react';
import { object, func, shape, array } from 'prop-types';
import { Modal, Button, Grid, Row, Col } from 'react-bootstrap';

import OrdersConstants from 'constants/Orders';

import Detail from './Detail';
import Teh from './Teh';
import Parts from './Parts';
import Works from './Works';
import Media from './Media';

const showConfirmedCheckbox = (status) => {
  return [
    OrdersConstants.Status.WAIT_APPROVE,
    OrdersConstants.Status.APPROVED,
  ].includes(status);
};

export class OrderView extends React.Component {
  static propTypes = {
    values: object,
    handleSubmit: func,
    lists: shape({
      wjobs: array,
      parts: array,
    }),
  };

  changeConfirm = ({ currentTarget: { checked } }) => {
    const { setFieldValue } = this.props;
    setFieldValue('confirmed', checked);
  };

  render() {
    const { Status } = OrdersConstants;
    const {
      handleSubmit, values, setFieldValue, isSubmitting, initialValues, onHistoryClick, lists, user, ...props
    } = this.props;
    const editable = !user.isReadOnly;
    const deletableMedia = (user.isRoot || user.isModerator) && values.status !== Status.APPROVED;

    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>
            Заявка {values.order_id}
            &nbsp;&nbsp;&nbsp;
            <If condition={values.order_id && editable}>
              <Button bsStyle="info" onClick={onHistoryClick}>История техники</Button>
            </If>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid>
            <Row>
              <Col md={6}>
                <Detail values={values} list={lists.mechanics} editable={editable} />
              </Col>
              <Col md={5}>
                <Teh values={values} {...props} editable={editable} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Parts values={values} setFieldValue={setFieldValue} list={lists.parts} editable={editable}/>
              </Col>
              <Col md={5}>
                <Works values={values} setFieldValue={setFieldValue} list={lists.wjobs} editable={editable}/>
              </Col>
            </Row>
            <If condition={values && Array.isArray(values.media) && values.media.length}>
              <Row>
                <Col md={11}>
                  <Media
                    mediaData={values.media}
                    user={props.user}
                    deletableMedia={deletableMedia}
                    reloadOrder={props.reloadOrder}
                  />
                </Col>
              </Row>
            </If>
          </Grid>
        </Modal.Body>

        <Modal.Footer>
          <If condition={editable && showConfirmedCheckbox(values.status)}>
            <label htmlFor="confirmed">
              <input
                id="confirmed"
                type="checkbox"
                disabled={initialValues.confirmed}
                checked={values.confirmed}
                onChange={this.changeConfirm}
              />
              &nbsp;
              Заявка подтверждена
              &nbsp;
              &nbsp;
            </label>
          </If>

          <If condition={editable}>
            <Button onClick={handleSubmit} bsStyle="primary" disabled={isSubmitting}>Сохранить</Button>
          </If>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

export default OrderView;
