import { Rest } from './api';

class SupervisorsAPI extends Rest {
  getAll(params = {}) {
    return this.api.get(this.url, { params: { ...params, parent_id: 'null' } })
      .then(({ data }) => data);
  }

  getList() {
    return this.api.get(`${this.url}/list`)
      .then(({ data: { items } }) => items);
  }
}

export default new SupervisorsAPI({ url: '/supervisors', primaryKey: 'supervisor_id', restore: true });

