import OrdersConstants from '../../../api/constants/Orders';

export default Object.assign({}, OrdersConstants, {
  i18n: {
    meh_status: [
      { value: OrdersConstants.MehStatus.NEW, title: 'Новая' },
      { value: OrdersConstants.MehStatus.DONE, title: 'Сделано' },
      { value: OrdersConstants.MehStatus.FAILCLIENT, title: 'Ложная заявка' },
      { value: OrdersConstants.MehStatus.LINK_TO_STRANGE, title: 'Странная' },
    ]
  }
});
